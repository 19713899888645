import { useState, useEffect } from "react";

import './Speaker.css';

import { SvgSetaRight, SvgDelete, SvgEdit, SvgSearch } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Page_Speaker(){
    
    const [ search, setSearch ]             = useState('');
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('speaker'));

    const [ itensPage, setItensPage ] = useState(30);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showDataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showDataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('speaker', setShowDataPage);
    }, []);
    
    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('speaker').forEach(item =>{
                    if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.office.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.contact.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.biography.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowDataPage(duplicate);

        }else if(value == ''){
            setShowDataPage(GetDataPage('speaker'));
        }
        setSearch(value);
    }

    function NewData(id){
        SetListPag('currentPage', 'speaker_details');
        SetListPag('currentPageId', id);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "speaker", "type": "delete_speaker", "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_Speaker">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Palestrante
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="list_opt_alt_page">
                    <div className="new_block_text" onClick={ ()=>{ NewData(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            Palestrantre
                        </div>
                    </div>
                </div>
            </div>
            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>Nome</th>
                            <th>Cargo</th>
                            <th width="80">Contato</th>
                            <th width="200">Palestra</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td align="left">{ startItens + index + 1 }</td>
                                            <td>{ key.name }</td>
                                            <td>{ key.office }</td>
                                            <td>{ key.contact }</td>
                                            <td>
                                                {
                                                    key.lecture.length == 0 ? "---" :
                                                    key.lecture.map((key_1, index_1)=>{
                                                        return(
                                                            <div className="show_data_list" key={ index_1 }>
                                                                <div className="">
                                                                    <span className="highlight">Título:</span> { key_1.title }
                                                                </div>
                                                                <div className="">
                                                                    <span className="highlight">Início:</span> { key_1.startDate }
                                                                </div>
                                                                <div className="">
                                                                    <span className="highlight">Término:</span> { key_1.endDate }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div>
                                                    <div onClick={ ()=>{ NewData(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhum palestrante encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showDataPage.length >= 30 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}