import React, { useState, useEffect } from "react";

import './VariousData.css';

import { SvgClose, SvgDelete, SvgImg, SvgLink } from "components/SvgFile";

import { GetUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_VariousData } from "services/RegisterData";
import { GetDataPage } from "interface/Data";

export default function PopUP_VariousData(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ link, setLink ]     = useState('');
    const [ file, setFile ]     = useState('');
    const [ status, setStatus ] = useState(false);

    useEffect(()=>{
        RegisterModalData('VariousData', setModaldata);
        RegisterModalObserver('VariousData', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setLink(InitialData('link'));
            setFile(InitialData('img'));
        }
    }, [showPopUp]);

    function InitialData(type){
        const newData = GetDataPage('various_data').find(item => item.id == modalData.id);
        return newData[type];
    }

    function SaveData(){
        Reg_VariousData(modalData.id, link, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
        ClosePopUp();
    }

    function ClosePopUp(){
        setStatus(false);
        SetModalState('VariousData', false);
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_variousdata">
                        <div className="div_data type_div">
                            <div className="title">
                                Editar dados
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="list_input_data">
                                    <div className="div_input space_width" style={ { flexGrow: 1 } }>
                                        <input type="text" className="space_width" onChange={ (e)=>{ setLink(e.target.value) } } value={ link.replaceAll(' ', '') } maxLength="140" />
                                        <span className="name_input">link</span>
                                    </div>
                                    <div className="div_input">
                                        <a href={ link } target="_blank">
                                            <SvgLink className="icons" color="#2D3C53" />
                                        </a>
                                    </div>
                                    <span className="div_input div_add_img">
                                        <div className="new_file_add">
                                            <label>
                                                Alterar imagem
                                                <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                    </span>
                                </div>
                                
                                <div className="list_input_data">
                                    <div className="show_img" style={ { flexGrow: 1 } }>
                                        {
                                            status == true ?
                                            <img alt="" src={ URL.createObjectURL(file) } className="img_" />:
                                            <img alt="" src={ "./assets/various_data/" + file } className="img_" />
                                        }
                                    </div>
                                </div>

                                <div className="register_data button_category" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
