import { useState, useEffect } from "react";

import './AccessDashboard.css';

export default function Page_AccessDashboard(){

    return(
        <div className="Page_AccessDashboard">

        </div>
    )
}