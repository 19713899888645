import { useState, useEffect } from "react";

import './OperationCenter.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgEdit, SvgSearch, SvgSetaRight } from "components/SvgFile";

import PopUP_OpenPartService from "components/PopUp/OpenPartService";
import PopUP_OpenOperations from "components/PopUp/OpenOperations";

export default function Page_OperationCenter(){
    
    const [ search, setSearch ]             = useState('');
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('mesa_operation_center'));
    const [ showButton, setShowButton ]     = useState(GetDataPage('mesa_operation_center_btn'));

    useEffect(()=>{
        RegisterDataPage('mesa_operation_center', setShowDataPage);
        RegisterDataPage('mesa_operation_center_btn', setShowButton);
    }, []);

    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('mesa_operation_center').forEach(item =>{
                    if(item.title_pt_br.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.title_en.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.title_es.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.subtitle_pt_br.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.subtitle_en.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.subtitle_es.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name_origin.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowDataPage(duplicate);

        }else if(value == ''){
            setShowDataPage(GetDataPage('mesa_operation_center'));
        }
        setSearch(value);
    }
    
    function NewData(id){
        SetListPag('currentPage', 'mesa_operation_center_details');
        SetListPag('currentPageId', id);
    }

    function OpenService(){
        SetModalState('OpenOperations', true);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "mesa_operation_center", "type": "delete_data", "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_OperationCenter">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Centro de operação
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="list_opt_alt_page">
                    <div className="new_data" onClick={ ()=>{ OpenService() } }>
                        <div className="">
                            Tipos de serviços
                        </div>
                    </div>
                    <div className="new_block_text" onClick={ ()=>{ NewData(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            Centro de operação
                        </div>
                    </div>
                </div>
            </div>
            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th width="200">Origem</th>
                            <th width="160">Title</th>
                            <th>Subtitle</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            showDataPage.length > 0 ?
                                showDataPage.map((key, index)=>{
                                    let name_origin = '';
                                    let dataButton = showButton.find(item => item.id == key.id_given);
                                    if(dataButton){
                                        name_origin = dataButton.name_pt_br;
                                    }
                                    return(
                                        <tr key={ index }>
                                            <td align="left">{ index + 1 }</td>
                                            <td>{ name_origin }</td>
                                            <td>{ key.title_pt_br }</td>
                                            <td>{ key.subtitle_pt_br }</td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    {/* 
                                                    <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div> 
                                                    */}
                                                    <div onClick={ ()=>{ NewData(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhum dado encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <PopUP_OpenOperations />
        </div>
    )
}