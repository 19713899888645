import { useState, useEffect } from "react";

import './VariousData.css';

import { SvgEdit, SvgLink, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import PopUP_VariousData from "components/PopUp/VariousData";

export default function Page_VariousData(){
    
    const [ search, setSearch ]             = useState('');
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('various_data'));

    useEffect(()=>{
        RegisterDataPage('various_data', setShowDataPage);
    }, []);

    function NewData(id){
        SetModalData("VariousData", { "id": id });
        SetModalState('VariousData', true);
    }

    return(
        <div className="Page_VariousData">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Dados fixos
                    </div>
                </div>
            </div>
            <div className="list_card">
                {
                    showDataPage.map((key, index)=>{
                        return(
                            <div className="div_card" key={ index }>
                                <div className="card_img">
                                    <img alt="" src={ "./assets/various_data/" + key.img } className="img_" />
                                </div>
                                <div className="card_name">
                                    <div className="card_data">
                                        {
                                            key.type != "jd_fidelity" ? 
                                            <>
                                                <div className="name_origin">Compra e financiamento</div>
                                                { key.name }
                                            </> :
                                            <>
                                                <div className="name_origin">John deere fidelidade</div>
                                                { key.name }
                                            </>
                                        }
                                    </div>
                                    <div className="card_link">
                                        <a href={ key.link } target="_blank">
                                            <SvgLink className="icons_" color="#2D3C53" />
                                        </a>
                                        <a onClick={ ()=>{ NewData(key.id) } }>
                                            <SvgEdit className="icons" color="#2D3C53" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <PopUP_VariousData />
        </div>
    )
}