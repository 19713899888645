import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_Upgrades } from "services/RegisterData";

import { SvgAddData, SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgTable } from "components/SvgFile";

export default function Page_Upgrades_Details(){

    const [ loading, setLoading ]       = useState(false);
    const [ idUser, setIdUser ]         = useState(GetUserData("id"));
    const [ dataPage, setDataPage ]     = useState(GetDataPage('mesa_upgrades_data'));
    const [ showButton, setShowButton ] = useState(GetDataPage('mesa_upgrades_buttons'));
    const [ idPage, setIdPage ]         = useState(GetListPag('currentPageId'));
    
    const [ idioma, setIdioma ]       = useState('pt_br');
    const [ textFixed, setTextFixed ] = useState({
        "text_1": {
            "pt_br": "Dados da mesa - Upgrades",
            "en": "Table Data - Upgrades",
            "es": "Tabla de Datos - Upgrades"
        },
        "text_2": {
            "pt_br": "Tipo",
            "en": "Type",
            "es": "Tipo"
        },
        "text_3": {
            "pt_br": "Posição",
            "en": "Position",
            "es": "Posición"
        },
        "text_4": {
            "pt_br": "Nome",
            "en": "Name",
            "es": "Nombre"
        },
        "text_5": {
            "pt_br": "Selo garantia",
            "en": "Guarantee seal",
            "es": "Sello de garantía"
        },
        "text_6": {
            "pt_br": "Selo financiamento",
            "en": "Financing stamp",
            "es": "Sello de financiacion"
        },
        "text_7": {
            "pt_br": "Conteúdo",
            "en": "Content",
            "es": "Contenido"
        },
        "text_8": {
            "pt_br": "Imagem",
            "en": "Image",
            "es": "Imagen"
        },
        "text_9": {
            "pt_br": "Texto",
            "en": "Text",
            "es": "Texto"
        },
        "text_10": {
            "pt_br": "Adicionar imagem",
            "en": "Add image",
            "es": "Añadir imagen"
        },
        "text_11": {
            "pt_br": "Imagem adicionada",
            "en": "Image added",
            "es": "Imagen añadida"
        },
        "text_12": {
            "pt_br": "Adicionar QrCode",
            "en": "Add QrCode",
            "es": "Añadir QrCode"
        },
        "text_13": {
            "pt_br": "QrCode adicionada",
            "en": "QrCode added",
            "es": "QrCode añadida"
        },
        "text_14": {
            "pt_br": "Título",
            "en": "Title",
            "es": "Título"
        }
    });

    const [ type, setType ]         = useState(InitialData(idioma, 'id_given'));
    const [ machine, setMachine ]   = useState(InitialData(idioma, 'machine'));
    
    const [ namePtBr, setNamePtBr ] = useState(InitialData(idioma, 'name_pt_br'));
    const [ nameEn, setNameEn ]     = useState(InitialData(idioma, 'name_en'));
    const [ nameEs, setNameEs ]     = useState(InitialData(idioma, 'name_es'));

    const [ position, setPosition ] = useState(InitialData(idioma, 'position'));
    const [ stamp_1, setStamp_1 ]   = useState(InitialData(idioma, 'stamp_1'));
    const [ stamp_2, setStamp_2 ]   = useState(InitialData(idioma, 'stamp_2'));
    const [ contents, setContents ] = useState(InitialData(idioma, 'contents'));

    const [ qrCode, setQrCode ] = useState(InitialData(idioma, 'qrCode'));

    const [ titlePtBr, setTitlePtBr ] = useState(InitialData(idioma, 'title_pt_br'));
    const [ titleEn, setTitleEn ]     = useState(InitialData(idioma, 'title_en'));
    const [ titleEs, setTitleEs ]     = useState(InitialData(idioma, 'title_es'));

    const [ imgQrCodePtBr, setImgQrCodePtBr ] = useState(InitialData(idioma, 'imgQrCode_pt_br'));
    const [ imgQrCodeEn, setImgQrCodeEn ]     = useState(InitialData(idioma, 'imgQrCode_en'));
    const [ imgQrCodeEs, setImgQrCodeEs ]     = useState(InitialData(idioma, 'imgQrCode_es'));

    const [ statusQrCodePtBr, setStatusQrCodePtBr ] = useState(false);
    const [ statusQrCodeEn, setStatusQrCodeEn ]     = useState(false);
    const [ statusQrCodeEs, setStatusQrCodeEs ]     = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage('mesa_upgrades_data', setDataPage);
        RegisterDataPage('mesa_upgrades_buttons', setShowButton);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('mesa_upgrades_data'));
        setShowButton(GetDataPage('mesa_upgrades_buttons'));
        setIdPage(GetListPag('currentPageId'));

        setType(InitialData(idioma, 'id_given'));
        setMachine(InitialData(idioma, 'machine'));

        setNamePtBr(InitialData(idioma, 'name_pt_br'));
        setNameEn(InitialData(idioma, 'name_en'));
        setNameEs(InitialData(idioma, 'name_es'));

        setPosition(InitialData(idioma, 'position'));
        setContents(InitialData(idioma, 'contents'));
        setStamp_1(InitialData(idioma, 'stamp_1'));
        setStamp_2(InitialData(idioma, 'stamp_2'));
        setQrCode(InitialData(idioma, 'qrCode'));

        setTitlePtBr(InitialData(idioma, 'title_pt_br'));
        setTitleEn(InitialData(idioma, 'title_en'));
        setTitleEs(InitialData(idioma, 'title_es'));

        setImgQrCodePtBr(InitialData(idioma, 'imgQrCode_pt_br'));
        setImgQrCodeEn(InitialData(idioma, 'imgQrCode_en'));
        setImgQrCodeEs(InitialData(idioma, 'imgQrCode_es'));

        setStatusQrCodePtBr(false);
        setStatusQrCodeEn(false);
        setStatusQrCodeEs(false);
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('mesa_upgrades_data'));
        setShowButton(GetDataPage('mesa_upgrades_buttons'));
        setIdPage(GetListPag('currentPageId'));

        setType(InitialData(idioma, 'id_given'));
        setMachine(InitialData(idioma, 'machine'));

        setNamePtBr(InitialData(idioma, 'name_pt_br'));
        setNameEn(InitialData(idioma, 'name_en'));
        setNameEs(InitialData(idioma, 'name_es'));

        setPosition(InitialData(idioma, 'position'));
        setContents(InitialData(idioma, 'contents'));
        setStamp_1(InitialData(idioma, 'stamp_1'));
        setStamp_2(InitialData(idioma, 'stamp_2'));
        setQrCode(InitialData(idioma, 'qrCode'));

        setTitlePtBr(InitialData(idioma, 'title_pt_br'));
        setTitleEn(InitialData(idioma, 'title_en'));
        setTitleEs(InitialData(idioma, 'title_es'));

        setImgQrCodePtBr(InitialData(idioma, 'imgQrCode_pt_br'));
        setImgQrCodeEn(InitialData(idioma, 'imgQrCode_en'));
        setImgQrCodeEs(InitialData(idioma, 'imgQrCode_es'));

        setStatusQrCodePtBr(false);
        setStatusQrCodeEn(false);
        setStatusQrCodeEs(false);
    }, [idPage]);

    useEffect(()=>{        
        ShowButtons();
        setType(InitialData(idioma, 'id_given'));
    }, [idioma]);

    function InitialData(typeIdioma, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'id_given'){
                let dataButton = showButton.find(item => item.id == newData[type]);
                if(dataButton){
                    switch (typeIdioma) {
                        case "pt_br":
                            return { "value": dataButton.id, "label": dataButton.name_pt_br }
                        case "en":
                            return { "value": dataButton.id, "label": dataButton.name_en }
                        case "es":
                            return { "value": dataButton.id, "label": dataButton.name_es }
                    }
                }
                return { "value": 0, "label": "#" }
            }
            if(type == 'stamp_1'){
                switch (newData[type]) {
                    case "Não":
                        return { "value": newData[type], "label": "Não" };
                    case "stamp_1.png":
                        return { "value": newData[type], "label": "12 Meses" };
                    case "stamp_2.png":
                        return { "value": newData[type], "label": "6 anos" };
                    case "stamp_3.png":
                        return { "value": newData[type], "label": "8 anos" };
                }  
            }
            if(type == 'stamp_2'){
                switch (newData[type]) {
                    case "Não":
                        return { "value": newData[type], "label": "Não" };
                    case "stamp_4.png":
                        return { "value": newData[type], "label": "Selo 12%" };
                    case "stamp_5.png":
                        return { "value": newData[type], "label": "Selo 1.9%" };
                }  
            }
            if(type == 'machine' || type == 'qrCode'){
                return { "value": newData[type], "label": newData[type] }; 
            }
            
            if(type == 'position'){
                switch (newData[type]) {
                    case "left":
                        return { "value": newData[type], "label": "Esquerda" };
                    case "right":
                        return { "value": newData[type], "label": "Direita" };
                }  
            }
            return newData[type];
        }
        if(type == 'stamp_1' || type == 'stamp_2' || type == 'machine' ||  type == 'qrCode'){
            return { "value": "Não", "label": "Não" };
        }
        if(type == 'contents'){
            return [{ "id": 0, "column": 0, "status": false, "type": "text", "text": "", "img": [{ "id": 0, "status": false, "img": "" }] }, { "id": 0, "column": 0, "status": false, "type": "img", "text": "", "img": [{ "id": 0, "status_pt_br": false, "status_en": false, "status_es": false, "img_pt_br": "", "img_en": "", "img_es": "" }] }];
        }
        return '';
    }

    function ShowButtons(){
        const options = []
        if(idioma == 'pt_br'){
            showButton.map((key, index)=>{
                options.push({
                    "value": key.id, "label": key.name_pt_br
                });
            })
        }
        if(idioma == 'en'){
            showButton.map((key, index)=>{
                options.push({
                    "value": key.id, "label": key.name_en
                });
            })
        }
        if(idioma == 'es'){
            showButton.map((key, index)=>{
                options.push({
                    "value": key.id, "label": key.name_es
                });
            })
        }
        return options;
    }

    function ReturnPage(){
        SetListPag('currentPage', 'mesa_upgrades');
        SetListPag('currentPageId', 'remuve');
    }

    function AddFile(type, value){
        if(type == 'imgQrCode_pt_br'){
            if(value){
                setStatusQrCodePtBr(true);
                setImgQrCodePtBr(value);
            }else {
                setStatusQrCodePtBr(false);
                setImgQrCodePtBr('');
            }

        }else if(type == 'imgQrCode_en'){
            if(value){
                setStatusQrCodeEn(true);
                setImgQrCodeEn(value);
            }else {
                setStatusQrCodeEn(false);
                setImgQrCodeEn('');
            }

        }else if(type == 'imgQrCode_es'){
            if(value){
                setStatusQrCodeEs(true);
                setImgQrCodeEs(value);
            }else {
                setStatusQrCodeEs(false);
                setImgQrCodeEs('');
            }

        }
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function NewPhoto(index){
        const newData = [...contents];
        newData[index].img.push({ "id": 0, "status_pt_br": false, "status_en": false, "status_es": false, "img_pt_br": "", "img_en": "", "img_es": "" });
        setContents(newData);
    }
    
    function HandleData(type, index, value, index_1){
        const newData = [...contents];
        if(type == 'img'){
            switch (idioma) {
                case "pt_br":
                        newData[index].img[index_1].img_pt_br    = value;
                        newData[index].img[index_1].status_pt_br = true;
                    break;
                case "en":
                        newData[index].img[index_1].img_en    = value;
                        newData[index].img[index_1].status_en = true;
                    break;
                case "es":
                        newData[index].img[index_1].img_es    = value;
                        newData[index].img[index_1].status_es = true;
                    break;
            }
        }else {
            newData[index][type] = value;
        }
        setContents(newData);
    }

    function DeleteData(type, id, index, value, index_1){
        if(type == 'data'){
            if(id == 0){
                const newData = [...contents];
                newData.splice(index, 1);
                setContents(newData)
            }else {
                SetModalData("Confirmation", { "id": id, "origin": "mesa_upgrades", "type": "delete_data_contents", "name": value });
                SetModalState('Confirmation', true);
            }
        }else {
            if(id == 0){
                const newData = [...contents];
                newData[index].img.splice(index_1, 1);
                setContents(newData)
            }else {
                SetModalData("Confirmation", { "id": id, "origin": "mesa_upgrades", "type": "delete_data_photo", "name": value });
                SetModalState('Confirmation', true);
            }
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Upgrades(idPage, type.value, machine.value, position.value, stamp_1.value, stamp_2.value, qrCode.value, titlePtBr, titleEn, titleEs, imgQrCodePtBr, imgQrCodeEn, imgQrCodeEs, namePtBr, nameEn, nameEs, contents, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Upgrades_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Upgrades
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>
                
                <div className="div_idioma">
                    <div className={ idioma == 'pt_br' ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ setIdioma('pt_br') } }>Português</div>
                    <div className={ idioma == 'en' ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ setIdioma('en') } }>Inglês</div>
                    <div className={ idioma == 'es' ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ setIdioma('es') } }>Espanhol</div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            <SvgTable color="#2D3C53" className="icons" />
                            { textFixed.text_1[idioma] }
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <Select className="select_2" defaultOptions options={ ShowButtons() } onChange={ (e)=> { setType(e) } } value={ type } placeholder="..." required />
                            <span className="name_input">{ textFixed.text_2[idioma] }</span>
                        </div>
                        <div className="div_input">
                            <Select className="select_1" defaultOptions options={[ { "value": "left", "label": "Esquerda" }, { "value": "right", "label": "Direita" } ]} onChange={ (e)=> { setPosition(e) } } value={ position } placeholder="..." required />
                            <span className="name_input">{ textFixed.text_3[idioma] }</span>
                        </div>
                        <div className="div_input">
                            {
                                idioma == 'pt_br' ?
                                    <input type="text" onChange={ (e)=>{ setNamePtBr(e.target.value) } } value={ namePtBr } maxLength="60" /> :
                                        idioma == 'en' ?
                                            <input type="text" onChange={ (e)=>{ setNameEn(e.target.value) } } value={ nameEn } maxLength="60" /> :
                                            <input type="text" onChange={ (e)=>{ setNameEs(e.target.value) } } value={ nameEs } maxLength="60" />
                            }
                            <span className="name_input">{ textFixed.text_4[idioma] }</span>
                        </div>
                        <div className="div_input">
                            <Select className="select_1" defaultOptions options={[ { "value": "Não", "label": "Não" }, { "value": "stamp_1.png", "label": "12 meses" }, { "value": "stamp_2.png", "label": "6 anos" }, { "value": "stamp_3.png", "label": "8 anos" } ]} onChange={ (e)=> { setStamp_1(e) } } value={ stamp_1 } placeholder="..." required />
                            <span className="name_input">{ textFixed.text_5[idioma] }</span>
                        </div>
                        <div className="div_input">
                            <Select className="select_3" defaultOptions options={[ { "value": "Não", "label": "Não" }, { "value": "stamp_4.png", "label": "Selo 12%" }, { "value": "stamp_5.png", "label": "Selo 1.9%" } ]} onChange={ (e)=> { setStamp_2(e) } } value={ stamp_2 } placeholder="..." required />
                            <span className="name_input">{ textFixed.text_6[idioma] }</span>
                        </div>
                        <div className="div_input">
                            <Select className="select_1" defaultOptions options={[ { "value": "Não", "label": "Não" }, { "value": "Sim", "label": "Sim" } ]} onChange={ (e)=> { setQrCode(e) } } value={ qrCode } placeholder="..." required />
                            <span className="name_input">QrCode</span>
                        </div>
                    </div>
                    {
                        qrCode.value == "Não" ? null :
                        <div className="list_input_data">
                            <div className="div_input space_div">
                                {
                                    idioma == 'pt_br' ?
                                        <input type="text" onChange={ (e)=>{ setTitlePtBr(e.target.value) } } value={ titlePtBr } maxLength="60" required /> :
                                            idioma == 'en' ?
                                                <input type="text" onChange={ (e)=>{ setTitleEn(e.target.value) } } value={ titleEn } maxLength="60" required /> :
                                                <input type="text" onChange={ (e)=>{ setTitleEs(e.target.value) } } value={ titleEs } maxLength="60" required />
                                }
                                <span className="name_input">{ textFixed.text_14[idioma] }</span>
                            </div>   
                            {
                                idioma == 'pt_br' ?
                                    <>                         
                                        <div className={ statusQrCodePtBr == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                            <label>
                                                { statusQrCodePtBr == true ? textFixed.text_13[idioma] : textFixed.text_12[idioma] }
                                                <input type="file" onChange={ (e)=>{ AddFile('imgQrCode_' + idioma, e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            imgQrCodePtBr !='' ?
                                                statusQrCodePtBr == true ?
                                                    <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(imgQrCodePtBr)) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div> :
                                                    <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/upgrades/" + imgQrCodePtBr) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div>
                                            : null
                                        }
                                    </>
                                    :
                                        idioma == 'en' ?
                                            <>                  
                                                <div className={ statusQrCodeEn == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                                    <label>
                                                        { statusQrCodeEn == true ? textFixed.text_13[idioma] : textFixed.text_12[idioma] }
                                                        <input type="file" onChange={ (e)=>{ AddFile('imgQrCode_' + idioma, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    imgQrCodeEn !='' ?
                                                        statusQrCodeEn == true ?
                                                            <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(imgQrCodeEn)) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div> :
                                                            <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/upgrades/" + imgQrCodeEn) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div>
                                                    : null
                                                }
                                            </>
                                            :
                                            <>          
                                                <div className={ statusQrCodeEs == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                                    <label>
                                                        { statusQrCodeEs == true ? textFixed.text_13[idioma] : textFixed.text_12[idioma] }
                                                        <input type="file" onChange={ (e)=>{ AddFile('imgQrCode_' + idioma, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    imgQrCodeEs !='' ?
                                                        statusQrCodeEs == true ?
                                                            <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(imgQrCodeEs)) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div> :
                                                            <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/upgrades/" + imgQrCodeEs) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div>
                                                    : null
                                                }
                                            </>
                            }
                        </div>
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            { textFixed.text_7[idioma] }
                        </div>
                        <div className="">
                            <div className="new_data_page" onClick={ ()=>{ setContents([...contents, { "id": 0, "column": 0, "status": false, "type": "img", "text_pt_br": "", "text_en": "", "text_es": "", "img": [{ "id": 0, "status": false, "img": "" }] }]) } }>
                                + { textFixed.text_7[idioma] }
                            </div>
                        </div>
                    </div>
                    {
                        contents.length > 0 ?
                            <div className="list_data_list">
                                {
                                    contents.map((key, index) =>{
                                        return(
                                            <div className={ key.type == 'img' ? "div_data_contents div_img" : "div_data_contents" } key={ index }>
                                                <div className="show_contents">
                                                    <div className="div_input">
                                                        <select className="" onChange={ (e)=>{ HandleData('type', index, e.target.value) } } value={ key.type } required>
                                                            <option value="img">{ textFixed.text_8[idioma] }</option>
                                                            <option value="text">{ textFixed.text_9[idioma] }</option>
                                                        </select>
                                                        <span className="name_input">Tipo</span>
                                                    </div>
                                                    <div className="div_input" onClick={ ()=>{ DeleteData('data', key.id, index, key.type) } }>
                                                        <SvgDelete color="#F00000" className="icons" />
                                                    </div> 
                                                    {
                                                        key.type != 'img' ? null :
                                                        <div className="div_input" onClick={ ()=>{ NewPhoto(index); } }>
                                                            <SvgAddData color="#2D3C53" className="icons" />
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    key.type == 'img' ? 
                                                    <>
                                                        {
                                                            key.img.map((key_1, index_1)=>{
                                                                return(
                                                                    <div className="show_contents" key={ index_1 }>
                                                                        {
                                                                            idioma == 'pt_br' ?
                                                                                <>
                                                                                    <div className={ key_1.status_pt_br == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                                        <label>
                                                                                            { key_1.status_pt_br == true ? textFixed.text_11[idioma] : textFixed.text_10[idioma] }
                                                                                            <input type="file" onChange={ (e)=>{ HandleData('img', index, e.target.files[0], index_1); } } accept="image/*" />
                                                                                        </label>
                                                                                    </div>
                                                                                    {
                                                                                        key_1.img_pt_br !='' ?
                                                                                            key_1.status_pt_br == true ?
                                                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_1.img_pt_br)) } }>
                                                                                                <SvgImg color="#324d6b" className="icons"/>
                                                                                            </div> :
                                                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/upgrades/" + key_1.img_pt_br) } }>
                                                                                                <SvgImg color="#324d6b" className="icons"/>
                                                                                            </div>
                                                                                        : null
                                                                                    }
                                                                                </> :
                                                                                idioma == 'en' ?
                                                                                    <>
                                                                                        <div className={ key_1.status_en == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                                            <label>
                                                                                                { key_1.status_en == true ? textFixed.text_11[idioma] : textFixed.text_10[idioma] }
                                                                                                <input type="file" onChange={ (e)=>{ HandleData('img', index, e.target.files[0], index_1); } } accept="image/*" />
                                                                                            </label>
                                                                                        </div>
                                                                                        {
                                                                                            key_1.img_en !='' ?
                                                                                                key_1.status_en == true ?
                                                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_1.img_en)) } }>
                                                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                                                </div> :
                                                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/upgrades/" + key_1.img_en) } }>
                                                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                                                </div>
                                                                                            : null
                                                                                        }
                                                                                    </>:
                                                                                    <>
                                                                                        <div className={ key_1.status_es == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                                            <label>
                                                                                                { key_1.status_es == true ? textFixed.text_11[idioma] : textFixed.text_10[idioma] }
                                                                                                <input type="file" onChange={ (e)=>{ HandleData('img', index, e.target.files[0], index_1); } } accept="image/*" />
                                                                                            </label>
                                                                                        </div>
                                                                                        {
                                                                                            key_1.img_es !='' ?
                                                                                                key_1.status_es == true ?
                                                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_1.img_es)) } }>
                                                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                                                </div> :
                                                                                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/upgrades/" + key_1.img_es) } }>
                                                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                                                </div>
                                                                                            : null
                                                                                        }
                                                                                    </>
                                                                        }
                                                                        <div className="" onClick={ ()=>{ DeleteData('img', key_1.id, index, key_1.type, index_1) } }>
                                                                            <SvgDelete color="#F00000" className="icons" />
                                                                        </div> 
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <div className="show_contents">
                                                        <JoditEditor config={ config } value={
                                                            idioma == 'pt_br' ? 
                                                                key.text_pt_br ? key.text_pt_br.replaceAll('&#34;', '"') : "" :
                                                                idioma == 'en' ? 
                                                                    key.text_en ? key.text_en.replaceAll('&#34;', '"') : "" :
                                                                    key.text_es ? key.text_es.replaceAll('&#34;', '"') : ""
                                                        } onBlur={ newContent => HandleData('text_' + idioma, index, newContent) } />
                                                    </div>
                                                }   
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        :                      
                        <div className="list_input_data" style={ { marginBottom: 0 } }>
                            <div className="no_data">Nenhum conteúdo encontrada...</div>
                        </div>
                    }
                </div>
            </form>
        </div>
    )
}