
let DataPage = {
    "access_dashboard" : [],
    "lecture"          : [],
    "speaker"          : [],
    "machine"          : [], 
    "various_data"     : [],
    
    "mesa_part_service_buttons" : [],
    "mesa_part_service_data"    : [],

    "expanded_portfolio": [],

    "mesa_unimil": [],

    "mesa_updates_buttons" : [],
    "mesa_updates_data"    : []
};

let NotifyDataPage = {
    "access_dashboard" : [],
    "lecture"          : [],
    "speaker"          : [],
    "schedule"         : [],
    "machine"          : [], 
    "various_data"     : [],

    "mesa_part_service_buttons" : [],
    "mesa_part_service_data"    : [],

    "expanded_portfolio": [],

    "mesa_unimil": [],

    "mesa_updates_buttons" : [],
    "mesa_updates_data"    : [],

    "mesa_operation_center_btn" : [],
    "mesa_operation_center"    : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["access_dashboard"].forEach(element => {
        element(value['access_dashboard']);
    });
    NotifyDataPage["lecture"].forEach(element => {
        element(value['lecture']);
    });
    NotifyDataPage["speaker"].forEach(element => {
        element(value['speaker']);
    });
    NotifyDataPage["machine"].forEach(element => {
        element(value['machine']);
    });
    NotifyDataPage["various_data"].forEach(element => {
        element(value['various_data']);
    });
    NotifyDataPage["expanded_portfolio"].forEach(element => {
        element(value['expanded_portfolio']);
    });
    NotifyDataPage["mesa_part_service_buttons"].forEach(element => {
        element(value['mesa_part_service_buttons']);
    });
    NotifyDataPage["mesa_part_service_data"].forEach(element => {
        element(value['mesa_part_service_data']);
    });
    NotifyDataPage["mesa_unimil"].forEach(element => {
        element(value['mesa_unimil']);
    });
    NotifyDataPage["mesa_updates_buttons"].forEach(element => {
        element(value['mesa_updates_buttons']);
    });
    NotifyDataPage["mesa_updates_data"].forEach(element => {
        element(value['mesa_updates_data']);
    });
    NotifyDataPage["mesa_operation_center_btn"].forEach(element => {
        element(value['mesa_operation_center_btn']);
    });
    NotifyDataPage["mesa_operation_center"].forEach(element => {
        element(value['mesa_operation_center']);
    });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
