import { useState, useEffect } from "react";

import './Upgrades.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete, SvgEdit, SvgSearch, SvgSetaDown, SvgSetaRight, SvgSetaUp } from "components/SvgFile";

import { Reg_AltPositionPartService } from "services/RegisterData";

import PopUP_OpenUpgrades from "components/PopUp/OpenUpgrades";

export default function Page_Upgrades(){
    
    const [ search, setSearch ]             = useState('');
    const [ showButton, setShowButton ]     = useState(GetDataPage('mesa_upgrades_buttons'));
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('mesa_upgrades_data'));

    useEffect(()=>{
        RegisterDataPage('mesa_upgrades_data', setShowDataPage);
        RegisterDataPage('mesa_upgrades_buttons', setShowButton);
    }, []);
    
    function SearchInput(value){
        const newData   = [];
        if(value !=''){
            {
                GetDataPage('mesa_upgrades_data').forEach(item =>{
                    if(item.name_pt_br.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name_en.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.name_es.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.title_pt_br.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.title_en.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    if(item.title_es.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                    
                    if(item.nameOrigin.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newData.push(item);
                    }
                })
            }
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
            setShowDataPage(duplicate);

        }else if(value == ''){
            setShowDataPage(GetDataPage('mesa_upgrades_data'));
        }
        setSearch(value);
    }

    function AltPosition(type, id, order_){
        Reg_AltPositionPartService(type, id, order_);
    }

    function OpenService(){
        SetModalState('OpenUpgrades', true);
    }

    function NewData(id){
        SetListPag('currentPage', 'mesa_upgrades_details');
        SetListPag('currentPageId', id);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "mesa_upgrades", "type": "delete_data", "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_PartsServices">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Upgrades
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="list_opt_alt_page">
                    <div className="new_data" onClick={ ()=>{ OpenService() } }>
                        <div className="">
                            Tipos de serviços
                        </div>
                    </div>
                    <div className="new_block_text" onClick={ ()=>{ NewData(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            upgrades
                        </div>
                    </div>
                </div>
            </div>
            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th width="180">Origem</th>
                            <th>Nome</th>
                            <th width="160">Posição</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            showDataPage.length > 0 ?
                                showDataPage.map((key, index)=>{
                                    let name_origin = '';
                                    let dataButton = showButton.find(item => item.id == key.id_given);
                                    if(dataButton){
                                        name_origin = dataButton.name_pt_br;
                                    }
                                    return(
                                        <tr key={ index }>
                                            <td align="left">{ index + 1 }</td>
                                            <td>{ name_origin }</td>
                                            <td>{ key.name_pt_br }</td>
                                            <td>{ key.position == 'left' ? "Esquerda" : "Direita" }</td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    {
                                                        index == 0 ? <div className="" style={ { width: '24px' } } /> :
                                                        <div className="" onClick={ ()=>{ AltPosition('up', key.id, key.order_) } } style={ { width: '24px' } }>
                                                            <SvgSetaUp className="icons" color="#2D3C53" />
                                                        </div>
                                                    }
                                                    {
                                                        showDataPage.length == (index + 1) ? <div className="" style={ { width: '24px' } } /> :
                                                        <div className="" onClick={ ()=>{ AltPosition('down', key.id, key.order_) } } style={ { width: '24px' } }>
                                                            <SvgSetaDown className="icons" color="#2D3C53" />
                                                        </div>
                                                    }
                                                    <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                        <SvgDelete color="#F00000" className="icons"/>
                                                    </div>
                                                    <div onClick={ ()=>{ NewData(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhum dado encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <PopUP_OpenUpgrades />
        </div>
    )
}