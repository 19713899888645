import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_OperationCenter, Reg_PartService } from "services/RegisterData";

import { SvgAddData, SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgTable, SvgVideo } from "components/SvgFile";

export default function Page_OperationCenter_Details(){

    const [ loading, setLoading ]       = useState(false);
    const [ idUser, setIdUser ]         = useState(GetUserData("id"));
    const [ dataPage, setDataPage ]     = useState(GetDataPage('mesa_operation_center'));
    const [ showButton, setShowButton ] = useState(GetDataPage('mesa_operation_center_btn'));
    const [ idPage, setIdPage ]         = useState(GetListPag('currentPageId'));

    const [ idioma, setIdioma ] = useState('pt_br');
    const [ origin, setOrigin ] = useState(InitialData(idioma, 'id_given'));
    
    const [ textFixed, setTextFixed ] = useState({
        "text_1": {
            "pt_br": "Dados da mesa - Operations Center",
            "en": "Table Data - Operations Center",
            "es": "Tabla de Datos - Centro de Operaciones"
        },
        "text_2": {
            "pt_br": "Origem",
            "en": "Origin",
            "es": "Origen"
        },
        "text_3": {
            "pt_br": "Posição",
            "en": "Position",
            "es": "Posición"
        },
        "text_4": {
            "pt_br": "Selo garantia",
            "en": "Guarantee seal",
            "es": "Sello de garantía"
        },
        "text_5": {
            "pt_br": "Selo financiamento",
            "en": "Financing stamp",
            "es": "Sello de financiacion"
        },
        "text_8": {
            "pt_br": "Conteúdo",
            "en": "Content",
            "es": "Contenido"
        },
        "text_9": {
            "pt_br": "Tipo",
            "en": "Type",
            "es": "Tipo"
        },
        "text_10": {
            "pt_br": "Imagem",
            "en": "Image",
            "es": "Imagen"
        },
        "text_11": {
            "pt_br": "Texto",
            "en": "Text",
            "es": "Texto"
        },
        "text_12": {
            "pt_br": "Adicionar imagem",
            "en": "Add image",
            "es": "Añadir imagen"
        },
        "text_13": {
            "pt_br": "Imagem adicionada",
            "en": "Image added",
            "es": "Imagen añadida"
        },
        "text_14": {
            "pt_br": "Adicionar vídeo",
            "en": "Add video",
            "es": "Añadir video"
        },
        "text_15": {
            "pt_br": "Vídeo adicionada",
            "en": "Video added",
            "es": "Vídeo añadido"
        }
    });

    const [ titlePtBr, setTitlePtBr ] = useState(InitialData(idioma, 'title_pt_br'));
    const [ titleEn, setTitleEn ]     = useState(InitialData(idioma, 'title_en'));
    const [ titleEs, setTitleEs ]     = useState(InitialData(idioma, 'title_es'));

    const [ subtitlePtBr, setSubtitlePtBr ] = useState(InitialData(idioma, 'subtitle_pt_br'));
    const [ subtitleEn, setSubtitleEn ]     = useState(InitialData(idioma, 'subtitle_en'));
    const [ subtitleEs, setSubtitleEs ]     = useState(InitialData(idioma, 'subtitle_es'));

    const [ contents, setContents ] = useState(InitialData(idioma, 'data'));
    const [ position, setPosition ] = useState(InitialData(idioma, 'position'));
    const [ stamp_1, setStamp_1 ]   = useState(InitialData(idioma, 'stamp_1'));
    const [ stamp_2, setStamp_2 ]   = useState(InitialData(idioma, 'stamp_2'));

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage('mesa_operation_center', setDataPage);
        RegisterDataPage('mesa_operation_center_btn', setShowButton);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('mesa_operation_center'));
        setShowButton(GetDataPage('mesa_operation_center_btn'));
        setIdPage(GetListPag('currentPageId'));

        setOrigin(InitialData(idioma, 'id_given'));

        setTitlePtBr(InitialData(idioma, 'title_pt_br'));
        setTitleEn(InitialData(idioma, 'title_en'));
        setTitleEs(InitialData(idioma, 'title_es'));

        setSubtitlePtBr(InitialData(idioma, 'subtitle_pt_br'));
        setSubtitleEn(InitialData(idioma, 'subtitle_en'));
        setSubtitleEs(InitialData(idioma, 'subtitle_es'));

        setContents(InitialData(idioma, 'data'));
        setPosition(InitialData(idioma, 'position'));
        setStamp_1(InitialData(idioma, 'stamp_1'));
        setStamp_2(InitialData(idioma, 'stamp_2'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('mesa_operation_center'));
        setShowButton(GetDataPage('mesa_operation_center_btn'));
        setIdPage(GetListPag('currentPageId'));

        setOrigin(InitialData(idioma, 'id_given'));

        setTitlePtBr(InitialData(idioma, 'title_pt_br'));
        setTitleEn(InitialData(idioma, 'title_en'));
        setTitleEs(InitialData(idioma, 'title_es'));

        setSubtitlePtBr(InitialData(idioma, 'subtitle_pt_br'));
        setSubtitleEn(InitialData(idioma, 'subtitle_en'));
        setSubtitleEs(InitialData(idioma, 'subtitle_es'));

        setContents(InitialData(idioma, 'data'));
        setPosition(InitialData(idioma, 'position'));
        setStamp_1(InitialData(idioma, 'stamp_1'));
        setStamp_2(InitialData(idioma, 'stamp_2'));
    }, [idPage]);

    useEffect(()=>{        
        ShowButtons();
        setOrigin(InitialData(idioma, 'id_given'));
    }, [idioma]);

    function InitialData(typeIdioma, type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);            
            if(type == 'id_given'){
                let dataButton = showButton.find(item => item.id == newData[type]);
                if(dataButton){
                    switch (typeIdioma) {
                        case "pt_br":
                            return { "value": dataButton.id, "label": dataButton.name_pt_br }
                        case "en":
                            return { "value": dataButton.id, "label": dataButton.name_en }
                        case "es":
                            return { "value": dataButton.id, "label": dataButton.name_es }
                    }
                }
                return { "value": 0, "label": "#" }  
            }
            if(type == 'stamp_1'){
                switch (newData[type]) {
                    case "Não":
                        return { "value": newData[type], "label": "Não" };
                    case "stamp_1.png":
                        return { "value": newData[type], "label": "12 Meses" };
                    case "stamp_2.png":
                        return { "value": newData[type], "label": "6 anos" };
                    case "stamp_3.png":
                        return { "value": newData[type], "label": "8 anos" };
                }  
            }
            if(type == 'stamp_2'){
                switch (newData[type]) {
                    case "Não":
                        return { "value": newData[type], "label": "Não" };
                    case "stamp_4.png":
                        return { "value": newData[type], "label": "Selo 12%" };
                    case "stamp_5.png":
                        return { "value": newData[type], "label": "Selo 1.9%" };
                }  
            }
            if(type == 'machine' || type == 'qrCode'){
                return { "value": newData[type], "label": newData[type] }; 
            }
            if(type == 'position'){
                switch (newData[type]) {
                    case "left":
                        return { "value": newData[type], "label": "Esquerda" };
                    case "right":
                        return { "value": newData[type], "label": "Direita" };
                }  
            }
            return newData[type];
        }
        if(type == 'data'){
            return [];
        }
        if(type == 'id_given'){
            return { "value": 0, "label": "#" }  
        }
        if(type == 'stamp_1' || type == 'stamp_2' || type == 'machine' ||  type == 'qrCode'){
            return { "value": "Não", "label": "Não" };
        }
        return '';
    }

    function ShowButtons(){
        const options = [];
        if(idioma == 'pt_br'){
            showButton.map((key, index)=>{
                options.push({
                    "value": key.id, "label": key.name_pt_br
                });
            })
        }
        if(idioma == 'en'){
            showButton.map((key, index)=>{
                options.push({
                    "value": key.id, "label": key.name_en
                });
            })
        }
        if(idioma == 'es'){
            showButton.map((key, index)=>{
                options.push({
                    "value": key.id, "label": key.name_es
                });
            })
        }
        return options;
    }

    function ReturnPage(){
        SetListPag('currentPage', 'mesa_operation_center');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_OperationCenter(idPage, origin.value, titlePtBr, titleEn, titleEs, subtitlePtBr, subtitleEn, subtitleEs, position.value, stamp_1.value, stamp_2.value, contents, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function DeleteData(type, id, index, value, index_1){
        // if(type == 'data'){
        //     if(id == 0){
        //         const newData = [...contents];
        //         newData.splice(index, 1);
        //         setContents(newData)
        //     }else {
        //         SetModalData("Confirmation", { "id": id, "origin": "mesa_operationcenter", "type": "delete_data_contents", "name": value });
        //         SetModalState('Confirmation', true);
        //     }
        // }else {
        //     if(id == 0){
        //         const newData = [...contents];
        //         newData[index].img.splice(index_1, 1);
        //         setContents(newData)
        //     }else {
        //         SetModalData("Confirmation", { "id": id, "origin": "mesa_operationcenter", "type": "delete_data_photo", "name": value });
        //         SetModalState('Confirmation', true);
        //     }
        // }
    }

    function HandleData(type, index, value, typeFile){
        const newData        = [...contents];
        newData[index][type] = value;
        if(typeFile == 'img'){
            if(value){
                newData[index]['statusImg_' + idioma] = true;
            }else {
                newData[index]['statusImg_' + idioma] = false;
            }         
        }else if(type == 'video'){
            if(value){
                newData[index]['statusVideo_' + idioma] = true;
            }else {
                newData[index]['statusVideo_' + idioma] = false;
            }         
        }
        setContents(newData);
    }

    return(
        <div className="Page_OperationCenter_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Centro de operação
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>
                
                <div className="div_idioma">
                    <div className={ idioma == 'pt_br' ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ setIdioma('pt_br') } }>Português</div>
                    <div className={ idioma == 'en' ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ setIdioma('en') } }>Inglês</div>
                    <div className={ idioma == 'es' ? "idioma idioma_active" : "idioma" } onClick={ ()=>{ setIdioma('es') } }>Espanhol</div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            <SvgTable color="#2D3C53" className="icons" />
                            { textFixed.text_1[idioma] }
                        </div>
                    </div>            
                    <div className="list_input_data">
                        <div className="div_input">
                            <Select className="select_1" defaultOptions options={ ShowButtons() } onChange={ (e)=> { setOrigin(e) } } value={ origin } placeholder="..." required />
                            <span className="name_input">{ textFixed.text_2[idioma] }</span>
                        </div> 
                        <div className="div_input">
                            <Select className="select_1" defaultOptions options={[ { "value": "left", "label": "Esquerda" }, { "value": "right", "label": "Direita" } ]} onChange={ (e)=> { setPosition(e) } } value={ position } placeholder="..." required />
                            <span className="name_input">{ textFixed.text_3[idioma] }</span>
                        </div>
                        <div className="div_input">
                            <Select className="select_1" defaultOptions options={[ { "value": "Não", "label": "Não" }, { "value": "stamp_1.png", "label": "12 meses" }, { "value": "stamp_2.png", "label": "6 anos" }, { "value": "stamp_3.png", "label": "8 anos" } ]} onChange={ (e)=> { setStamp_1(e) } } value={ stamp_1 } placeholder="..." required />
                            <span className="name_input">{ textFixed.text_4[idioma] }</span>
                        </div>
                        <div className="div_input">
                            <Select className="select_3" defaultOptions options={[ { "value": "Não", "label": "Não" }, { "value": "stamp_4.png", "label": "Selo 12%" }, { "value": "stamp_5.png", "label": "Selo 1.9%" } ]} onChange={ (e)=> { setStamp_2(e) } } value={ stamp_2 } placeholder="..." required />
                            <span className="name_input">{ textFixed.text_5[idioma] }</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        {
                            idioma == 'pt_br' ?
                            <>             
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setTitlePtBr(e.target.value) } } value={ titlePtBr } maxLength="60" />
                                    <span className="name_input">Título</span>
                                </div> 
                                <div className="div_input space_div">
                                    <input type="text" onChange={ (e)=>{ setSubtitlePtBr(e.target.value) } } value={ subtitlePtBr } maxLength="60" />
                                    <span className="name_input">Subtítulo</span>
                                </div>           
                            </> :
                                idioma == 'en' ? 
                                <>                   
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setTitleEn(e.target.value) } } value={ titleEn } maxLength="60" />
                                        <span className="name_input">Title</span>
                                    </div> 
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ setSubtitleEn(e.target.value) } } value={ subtitleEn } maxLength="60" />
                                        <span className="name_input">Caption</span>
                                    </div> 
                                </> :
                                    <>                   
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ setTitleEs(e.target.value) } } value={ titleEs } maxLength="60" />
                                            <span className="name_input">Título</span>
                                        </div> 
                                        <div className="div_input space_div">
                                            <input type="text" onChange={ (e)=>{ setSubtitleEs(e.target.value) } } value={ subtitleEs } maxLength="60" />
                                            <span className="name_input">Subtítulo</span>
                                        </div> 
                                    </>
                        }
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            { textFixed.text_8[idioma] }
                        </div>
                        <div className="">
                            <div className="new_data_page" onClick={ ()=>{ setContents([...contents, { "id": 0, "type": "", "text_pt_br": "", "text_en": "", "text_es": "", "file": "", "statusImg_pt_br": false, "statusImg_en": false, "statusImg_es": false, "video_pt_br": "", "video_en": "", "video_es": "", "statusVideo_pt_br": false, "statusVideo_en": false, "statusVideo_es": false }]) } }>
                                + { textFixed.text_8[idioma] }
                            </div>
                        </div>
                    </div> 
                    {
                        contents.map((key, index) =>{
                            return(
                                <div className="div_contents_data" key={ index }>
                                    <div className="list_input_data" style={ { marginTop: 0 } }>
                                        <div className="div_input">
                                            <select className="" onChange={ (e)=>{ HandleData('type', index, e.target.value) } } value={ key.type } required>
                                                <option value="">#</option>
                                                <option value="img">{ textFixed.text_10[idioma] }</option>
                                                <option value="text">{ textFixed.text_11[idioma] }</option>
                                                <option value="video">Vídeo</option>
                                            </select>
                                            <span className="name_input">{ textFixed.text_9[idioma] }</span>
                                        </div>
                                        {
                                            key.type == 'img' || key.type == 'video' ? 
                                            <>                                            
                                                <div className={ key["statusImg_" + idioma] == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                                    <label>
                                                        { key["statusImg_" + idioma] == true ? textFixed.text_13[idioma] : textFixed.text_12[idioma] }
                                                        <input type="file" onChange={ (e)=>{ HandleData('file_' + idioma, index, e.target.files[0], 'img'); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key["file_" + idioma] !='' ?
                                                        key["statusImg_" + idioma] == true ?
                                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key["file_" + idioma])) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/operation_center/" + key["file_" + idioma]) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                    : null
                                                } 
                                            </> : null
                                        }
                                        {
                                            key.type == 'video' ? 
                                            <>                                            
                                                <div className={ key["statusVideo_" + idioma] == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                                    <label>
                                                        { key["statusVideo_" + idioma] == true ? textFixed.text_15[idioma] : textFixed.text_14[idioma] }
                                                        <input type="file" onChange={ (e)=>{ HandleData('video_' + idioma, index, e.target.files[0], 'video'); } } accept="video/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key["video_" + idioma] !='' ?
                                                        key["statusVideo_" + idioma] == true ?
                                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showVideo_local', URL.createObjectURL(key["video_" + idioma])) } }>
                                                            <SvgVideo color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showVideo', "mesa/operation_center/" + key["video_" + idioma]) } }>
                                                            <SvgVideo color="#324d6b" className="icons"/>
                                                        </div>
                                                    : null
                                                } 
                                            </> : null
                                        }

                                    </div>
                                    {
                                        key.type == 'text' ? 
                                        <div className="list_input_data">
                                            <div className="space_div">
                                                <JoditEditor config={ config } value={
                                                    idioma == 'pt_br' ? 
                                                        key.text_pt_br ? key.text_pt_br.replaceAll('&#34;', '"') : "" :
                                                        idioma == 'en' ? 
                                                            key.text_en ? key.text_en.replaceAll('&#34;', '"') : "" :
                                                            key.text_es ? key.text_es.replaceAll('&#34;', '"') : ""
                                                } onBlur={ newContent => HandleData('text_' + idioma, index, newContent) } />
                                            </div>
                                        </div> : null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}