import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { SvgDelete, SvgLecture, SvgSave, SvgSetaRight, SvgSpeaker } from "components/SvgFile";
import { Reg_Lecture } from "services/RegisterData";
import PopUP_Thematic from "components/PopUp/Thematic";

export default function Page_Lecture_Details(){

    const [ loading, setLoading ]         = useState(false);
    const [ idUser, setIdUser ]           = useState(GetUserData("id"));
    const [ dataPage, setDataPage ]       = useState(GetDataPage('lecture'));
    const [ dataSpeaker, setDataSpeaker ] = useState(GetDataPage('speaker'));
    const [ idPage, setIdPage ]           = useState(GetListPag('currentPageId'));

    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ thematic, setThematic ]   = useState(InitialData('thematic'));
    const [ startDate, setStartDate ] = useState(InitialData('startDate'));
    const [ endDate, setEndDate ]     = useState(InitialData('endDate'));
    const [ highlight, setHighlight ] = useState(InitialData('highlight'));
    const [ biography, setBiography ] = useState(InitialData('biography'));
    const [ speaker, setSpeaker ]     = useState(InitialData('speaker'));

    const [ newSpeaker, setNewSpeaker ] = useState([]);
    const [ status, setStatus ]         = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage('lecture', setDataPage);
        RegisterDataPage('speaker', setDataSpeaker);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('lecture'));
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setThematic(InitialData('thematic'));
        setStartDate(InitialData('startDate'));
        setEndDate(InitialData('endDate'));
        setHighlight(InitialData('highlight'));
        setBiography(InitialData('biography'));
        setSpeaker(InitialData('speaker'));

        setNewSpeaker([]);
        setStatus(false);
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('lecture'));
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setThematic(InitialData('thematic'));
        setStartDate(InitialData('startDate'));
        setEndDate(InitialData('endDate'));
        setHighlight(InitialData('highlight'));
        setBiography(InitialData('biography'));
        setSpeaker(InitialData('speaker'));

        setNewSpeaker([]);
        setStatus(false);
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'highlight'){
                if(newData['highlight'] == 0){
                    return { "value": 0, "label": "Não" }
                }else {
                    return { "value": 1, "label": "Sim" }
                }
            }

            if(type == 'thematic'){
                return { "value": newData['thematic'], "label": newData['thematic'] }
            }
            return newData[type];
        }
        if(type == 'speaker'){
            return [];
        }
        return '';
    }

    function ListThematic(){
        const options = [{ "value": "add", "label": "Adicionar nova temática" }]
        dataPage.map((key, index)=>{
            if(key.thematic !=''){
                options.push({
                    "value": key.thematic, "label": key.thematic
                });
            }
        });
        const duplicate = options.filter((item, index) => options.indexOf(item) === index);
        return duplicate;
    }

    function AltThematic(value){
        if(value.label == 'Adicionar nova temática'){
            SetModalData("Thematic", { "id": idPage });
            SetModalState('Thematic', true);
        }else {
            setThematic(value);
        }
    }

    function RegThematic(value){
        setThematic({ "value": value, "label": value });
    }

    function ListSpeaker(){
        const options = []
        dataSpeaker.map((key, index)=>{
            let label = "Nome: " + key.name + " - Cargo: " + key.office
            options.push({
                "value": key.id, "label": label
            });
        });
        return options;
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "lecture", "type": "delete_speaker", "name": value });
        SetModalState('Confirmation', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'lecture');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Lecture(idPage, title, thematic.value, startDate, endDate, highlight.value, biography, newSpeaker, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Lecture_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Palestra
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            <SvgLecture color="#2D3C53" className="icons" />
                            Dados da palestra
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="datetime-local" onChange={ (e)=>{ setStartDate(e.target.value) } } value={ startDate } required />
                            <span className="name_input">Data de ínicio</span>
                        </div>
                        <div className="div_input">
                            <input type="datetime-local" onChange={ (e)=>{ setEndDate(e.target.value) } } value={ endDate } required />
                            <span className="name_input">Data de término</span>
                        </div>
                        <div className="div_input space_div">
                            <Select className="select_thematic" defaultOptions options={ ListThematic() } onChange={ (e)=> { AltThematic(e) } } value={ thematic } placeholder="..." />
                            <span className="name_input">Temática</span>
                        </div>
                        <div className="div_input">
                            <Select className="select_highlight" defaultOptions options={[ { "value": 0, "label": "Não" }, { "value": 1, "label": "Sim" } ]} onChange={ (e)=> { setHighlight(e) } } value={ highlight } placeholder="..." />
                            <span className="name_input">Destacar</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="name_input">Título</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Descrição</div>
                            <JoditEditor config={ config } value={ biography ? biography.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setBiography(newContent) } />
                        </div>
                    </div> 
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            <SvgSpeaker color="#2D3C53" className="icons" />
                            Palestrantes
                        </div>
                        <div className="">
                            <div className="new_data_page" onClick={ ()=>{ setStatus(!status) } }>
                                { status == false ? "+ Palestrante" : "Ocultar" }
                            </div>
                        </div>
                    </div>
                    {
                        status == false ? null :
                        <div className="list_input_data" style={ { marginBottom: '16px' } }>
                            <div className="div_input space_div">
                                <Select className="select_options" defaultOptions options={ ListSpeaker() } onChange={ (e)=> { setNewSpeaker(e) } } value={ newSpeaker } placeholder="..." isMulti />
                                <span className="name_input">Selecione os palestrantes</span>
                            </div>
                        </div>
                    }
                    {
                        speaker.length > 0 ?
                            <div className="list_data_list">
                                {
                                    speaker.map((key, index) =>{
                                        return(
                                            <div className="div_data_list" key={ index }>
                                                <div className="div_show_data_list">
                                                    <div className="">
                                                        <span className="highlight">Nome:</span> { key.name }
                                                    </div>
                                                    <div className="">
                                                        <span className="highlight">Contato:</span> { key.contact }
                                                    </div>
                                                </div>
                                                <div className="div_delete_data_list" onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                    <SvgDelete color="#F00000" className="icons" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        :
                        <>
                            {
                                status == true ? null :                        
                                <div className="list_input_data" style={ { marginBottom: 0 } }>
                                    <div className="no_data">Nenhum palestrante encontrado...</div>
                                </div>
                            }
                        </>
                    }
                </div>
            </form>
            <PopUP_Thematic RegThematic={ RegThematic } />
        </div>
    )
}