import { useState, useEffect } from "react";

import './Unimil.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgDelete, SvgEdit, SvgSearch, SvgSetaDown, SvgSetaRight, SvgSetaUp } from "components/SvgFile";

import PopUP_OpenPartService from "components/PopUp/OpenPartService";

export default function Page_Unimil(){
    
    const [ search, setSearch ]             = useState('');
    const [ showDataPage, setShowDataPage ] = useState(GetDataPage('mesa_unimil'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);
    
    const page         = Math.ceil(showDataPage.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showDataPage.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('mesa_unimil', setShowDataPage);
    }, []);

    function NewData(id){
        SetListPag('currentPage', 'mesa_unimil_details');
        SetListPag('currentPageId', id);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "mesa_unimil", "type": "delete_data", "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_Unimil">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Unimil
                    </div>
                </div>
            </div>
            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>idioma</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                                currentItens.map((key, index)=>{
                                    return(
                                        <tr key={ index }>
                                            <td align="left">{ startItens + index + 1 }</td>
                                            <td>{ key.idioma }</td>
                                            <td align="right">
                                                <div className="list_opt">
                                                    <div onClick={ ()=>{ NewData(key.id) } }>
                                                        <SvgEdit color="#324d6b" className="icons"/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            :
                            <tr>
                                <td colSpan="7">
                                    <div className="no_data">Nenhum dado encontrado...</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    showDataPage.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            <PopUP_OpenPartService />
        </div>
    )
}