import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_Machine } from "services/RegisterData";

import { SvgDelete, SvgImg, SvgMachine, SvgPdf, SvgSave, SvgSetaRight } from "components/SvgFile";

export default function Page_Machine_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ idUser, setIdUser ]     = useState(GetUserData("id"));
    const [ dataPage, setDataPage ] = useState(GetDataPage('machine'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ name, setName ]             = useState(InitialData('name'));
    const [ reference, setReference ]   = useState(InitialData('reference'));
    const [ title, setTitle ]           = useState(InitialData('title'));
    const [ nameFile, setNameFile ]     = useState(InitialData('nameFile'));
    const [ imgExample, setImgExample ] = useState(InitialData('imgExample'));
    const [ datasheet, setDatasheet ]   = useState(InitialData('datasheet'));
    const [ thumbnail, setThumbnail ]   = useState(InitialData('thumbnail'));
    const [ link, setLink ]             = useState(InitialData('link'));
    const [ obs, setObs ]               = useState(InitialData('obs'));
    const [ specialist, setSpecialist ] = useState(InitialData('specialist'));
    const [ gallery, setGallery ]       = useState(InitialData('gallery'));

    const [ statusImgExample, setStatusImgExample ] = useState(false);
    const [ statusDatasheet, setStatusDatasheet ]   = useState(false);
    const [ statusThumbnail, setStatusThumbnail ]   = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage('machine', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('machine'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setReference(InitialData('reference'));
        setTitle(InitialData('title'));
        setNameFile(InitialData('nameFile'));
        setImgExample(InitialData('imgExample'));
        setDatasheet(InitialData('datasheet'));
        setThumbnail(InitialData('thumbnail'));
        setLink(InitialData('link'));
        setObs(InitialData('obs'));
        setSpecialist(InitialData('specialist'));
        setGallery(InitialData('gallery'));

        setStatusImgExample(false);
        setStatusDatasheet(false);
        setStatusThumbnail(false);
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('machine'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setReference(InitialData('reference'));
        setTitle(InitialData('title'));
        setNameFile(InitialData('nameFile'));
        setImgExample(InitialData('imgExample'));
        setDatasheet(InitialData('datasheet'));
        setThumbnail(InitialData('thumbnail'));
        setLink(InitialData('link'));
        setObs(InitialData('obs'));
        setSpecialist(InitialData('specialist'));
        setGallery(InitialData('gallery'));

        setStatusImgExample(false);
        setStatusDatasheet(false);
        setStatusThumbnail(false);
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            console.log(newData);
            return newData[type];
        }
        if(type == 'gallery'){
            return [];
        }
        return '';
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "machine", "type": "delete_photo", "name": value });
        SetModalState('Confirmation', true);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function AddFile(type, value){
        if(type == 'pdf'){
            if(value){
                setStatusDatasheet(true);
                setDatasheet(value);
            }else {
                setStatusDatasheet(false);
                setDatasheet('');
            }
        }else if(type == 'img'){
            if(value){
                setStatusThumbnail(true);
                setThumbnail(value);
            }else {
                setStatusThumbnail(false);
                setThumbnail('');
            }
        }else if(type == 'imgExample'){
            if(value){
                setStatusImgExample(true);
                setImgExample(value);
            }else {
                setStatusImgExample(false);
                setImgExample('');
            }
        }
    }

    function DeleteImg(type, statusFile, value){
        switch (type) {
            case "imgExample":
                    if(statusFile == true){
                        setStatusImgExample(false);
                        setImgExample('');
                    }else {
                        SetModalData("Confirmation", { "id": idPage, "origin": "machine", "type": "delete_img_example", "name": "Imagem de exemplo" });
                        SetModalState('Confirmation', true);
                    }   
                break;

            case "thumbnail":
                    if(statusFile == true){
                        setStatusThumbnail(false);
                        setThumbnail('');
                    }else {
                        SetModalData("Confirmation", { "id": idPage, "origin": "machine", "type": "delete_thumbnail", "name": "Thumbnail" });
                        SetModalState('Confirmation', true);
                    }   
                break;

            case "datasheet":
                    if(statusFile == true){
                        setStatusDatasheet(false);
                        setDatasheet('');
                    }else {
                        SetModalData("Confirmation", { "id": idPage, "origin": "machine", "type": "delete_datasheet", "name": "Ficha técnica" });
                        SetModalState('Confirmation', true);
                    }  
                break;
        }
    }

    function ReturnPage(){
        SetListPag('currentPage', 'machine');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Machine(idPage, name, reference, title, imgExample, datasheet, thumbnail, link, obs, specialist, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Page_Machine_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Máquinas
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            <SvgMachine color="#2D3C53" className="icons" />
                            Dados da máquina
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome da máquina</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="reference" onChange={ (e)=>{ setReference(e.target.value) } } value={ reference } maxLength="60" required />
                            <span className="name_input">Referência</span>
                        </div> 
                        <span className="div_input div_add_img">
                            <div className={ statusImgExample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusImgExample == true ? "Imagem adicionada" : "Ícone da linha" }
                                    <input type="file" onChange={ (e)=>{ AddFile('imgExample', e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                imgExample !='' ?
                                    <>                                        
                                        <div className="delete_img_add" onClick={ ()=>{ DeleteImg("imgExample", statusImgExample, imgExample) } }>
                                            <SvgDelete className="icons" color="#ffffff" />
                                        </div>
                                        {
                                            statusImgExample == true ?
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(imgExample)) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div> :
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "machine/photo/" + imgExample) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div>
                                        }
                                    </>
                                : null
                            }
                        </span>

                        <span className="div_input div_add_img">
                            <div className={ statusThumbnail == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusThumbnail == true ? "Thumbnail adicionada" : "Adicionar thumbnail" }
                                    <input type="file" onChange={ (e)=>{ AddFile('img', e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                thumbnail !='' ?
                                    <>                                    
                                        <div className="delete_img_add" onClick={ ()=>{ DeleteImg("thumbnail", statusThumbnail, thumbnail) } }>
                                            <SvgDelete className="icons" color="#ffffff" />
                                        </div>
                                        {
                                            statusThumbnail == true ?
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(thumbnail)) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div> :
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "machine/datasheet/thumbnail/" + thumbnail) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div>
                                        }
                                    </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="name_input">Nome do arquivo da ficha técnica</span>
                        </div>     

                        <span className="div_input div_add_img">
                            <div className={ statusDatasheet == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusDatasheet == true ? "Ficha técnica adicionada" : "Adicionar ficha técnica" }
                                    <input type="file" onChange={ (e)=>{ AddFile('pdf', e.target.files[0]); } } accept="application/pdf" />
                                </label>
                            </div>
                            {
                                datasheet !='' ?
                                    <>                                    
                                        <div className="delete_img_add" onClick={ ()=>{ DeleteImg("datasheet", statusDatasheet, datasheet) } }>
                                            <SvgDelete className="icons" color="#ffffff" />
                                        </div>
                                        {
                                            statusDatasheet == true ?
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf_local', URL.createObjectURL(datasheet)) } }>
                                                <SvgPdf color="#324d6b" className="icons"/>
                                            </div> :
                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showPdf', "machine/datasheet/" + datasheet) } }>
                                                <SvgPdf color="#324d6b" className="icons"/>
                                            </div>
                                        }
                                    </>
                                : null
                            } 
                        </span>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setSpecialist(e.target.value) } } value={ specialist.replaceAll(' ', '') } />
                            <span className="name_input">Vídeo do especialista</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setLink(e.target.value) } } value={ link.replaceAll(' ', '') } />
                            <span className="name_input">Link do site</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Descrição</div>
                            <JoditEditor config={ config } value={ obs ? obs.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setObs(newContent) } />
                        </div>
                    </div> 
                </div>
            </form>
        </div>
    )
}