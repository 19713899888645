import { useState, useEffect } from "react";

import './Details.css';

export default function Page_AccessDashboard_Details(){

    return(
        <div className="Page_AccessDashboard_Details">

        </div>
    )
}