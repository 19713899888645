import { useState, useEffect } from "react";

import './ExpandedPortfolio.css';

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

import { SvgEdit, SvgSetaRight } from "components/SvgFile";

import PopUP_OpenOperations from "components/PopUp/OpenOperations";

export default function Page_ExpandedPortfolio(){
    const [ listData, setListData ] = useState([
        {
            "type": "all/pt_br",
            "name": "Português"
        },
        {
            "type": "all/en",
            "name": "Inglês"
        },
        {
            "type": "all/es",
            "name": "Espanhol"
        },

        {
            "type": "grain_harvest/pt_br",
            "name": "Mesa Colheita grãos - Português"
        },
        
        {
            "type": "utility_tractor/pt_br",
            "name": "Mesa Trator utilitário - Português"
        },
        {
            "type": "utility_tractor/en",
            "name": "Mesa Trator utilitário - Inglês"
        },
        {
            "type": "utility_tractor/es",
            "name": "Mesa Trator utilitário - Espanhol"
        }
    ]);
    
    function NewData(id){
        SetListPag('currentPage', 'expanded_portfolio_details');
        SetListPag('currentPageId', id);
    }

    function OpenService(){
        SetModalState('OpenOperations', true);
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "expanded_portfolio", "type": "delete_data", "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Page_OperationCenter">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#CDD1D6" className="icons" />
                    </div>
                    <div className="name_page">
                        Portfólio ampliado
                    </div>
                </div>
                {/* 
                <div className="list_opt_alt_page">
                    <div className="new_block_text" onClick={ ()=>{ NewData(0) } }>
                        <div className="">
                            <span className="icons_menu">+</span>
                        </div>
                        <div className="">
                            Portfólio ampliado
                        </div>
                    </div>
                </div> 
                */}
            </div>
            <div className="">                
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20">#</th>
                            <th>Idioma</th>
                            <th width="20" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            listData.map((key, index)=>{                               
                                return(
                                    <tr key={ index }>
                                        <td align="left">{ index + 1 }</td>
                                        <td>{ key.name }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ NewData(key.type) } }>
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            <PopUP_OpenOperations />
        </div>
    )
}