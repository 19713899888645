import React, { useState, useEffect } from "react";

import './OpenUpgrades.css';

import { SvgClose, SvgImg } from "components/SvgFile";

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { Reg_ButtonUpgrades } from "services/RegisterData";

export default function PopUP_OpenUpgrades(){

    const [ loading, setLoading ]     = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ dataPage, setDataPage ]   = useState([]);

    useEffect(()=>{
        RegisterModalData('OpenUpgrades', setModaldata);
        RegisterModalObserver('OpenUpgrades', setShowPopUp);

        RegisterDataPage('mesa_upgrades_buttons', setDataPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setDataPage(GetDataPage('mesa_upgrades_buttons'));
        }
    }, [showPopUp]);

    function SaveData(){
        setLoading(true);
        Reg_ButtonUpgrades(dataPage, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        ClosePopUp();
    }

    function CallbackError(){
        setLoading(false);
        ClosePopUp();
    }

    function ClosePopUp(){
        SetModalState('OpenUpgrades', false);
    }

    function AddFile(index, value){
        const newData = [...dataPage];        
        if(value){
            newData[index].img    = value;
            newData[index].status = true;
        }else {
            newData[index].img    = '';
            newData[index].status = false;
        }
        setDataPage(newData);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                        <img alt="loading" src="./assets/loading.gif" className="loading" />
                    </div>
                    <div className="all popup_openpartservice">
                        <div className="div_data type_div">
                            <div className="title">
                                Tipos de serviços
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <table cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th width="20">#</th>
                                            <th align="left">Nome</th>
                                            <th width="120" align="right"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dataPage.map((key, index)=>{
                                                return(
                                                    <tr key={ index }>
                                                        <td>{ index + 1 }</td>
                                                        <td align="left">{ key.name_pt_br }</td>
                                                        <td align="right">
                                                            <div className="div_input">
                                                                <div className={ key.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                    <label>
                                                                        { key.status == true ? "Foto adicionada" : "Adicionar foto" }
                                                                        <input type="file" onChange={ (e)=>{ AddFile(index, e.target.files[0]); } } accept="image/*" />
                                                                    </label>
                                                                </div>
                                                                {
                                                                    key.img !='' ?
                                                                        key.status == true ?
                                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.img)) } }>
                                                                            <SvgImg color="#324d6b" className="icons"/>
                                                                        </div> :
                                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/upgrades/" + key.img) } }>
                                                                            <SvgImg color="#324d6b" className="icons"/>
                                                                        </div>
                                                                    : null
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div className="register_data button_category" onClick={ ()=>{ SaveData() } }>
                                    Cadastrar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
