import { cookiesRegister, typeCookieNewId, typeCookiePage, typeCookiePageId } from 'fixedData';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

let ListPag = {
    "page"          : 'login',
    "currentPage"   : 'index',
    "currentPageId" : '',
    "idNewRegister" : ''
};

let NotifyListPag = {
    "page"          : [],
    "currentPage"   : [],
    "currentPageId" : [],
    "idNewRegister" : []
}

export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    if(key == 'currentPage'){
        cookies.set(typeCookiePage, value, '/', cookiesRegister);
        cookies.remove(typeCookieNewId, '', '/', cookiesRegister);
    }

    if(key == 'idNewRegister'){
        cookies.set(typeCookieNewId, value, '/', cookiesRegister);
    }

    if(key == 'currentPageId'){
        if(value == 'remuve'){
            cookies.remove(typeCookiePageId, '', '/', cookiesRegister);
            cookies.remove(typeCookieNewId, '', '/', cookiesRegister);
        }else {
            cookies.set(typeCookiePageId, value, '/', cookiesRegister);
        }
    }
}

export function GetListPag(key){
    return ListPag[key];
}

export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = [];
    }
    NotifyListPag[key].push(value);
}
