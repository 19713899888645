import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePageId, typeCookiePassw } from 'fixedData';

// Delete
export function Reg_Delete(userId, id, origin, type, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        switch (origin) {
            case 'lecture':
                    SetListDataSingle('lecture', response.data.lecture);
                break;
            case 'speaker':
                    SetListDataSingle('speaker', response.data.speaker);
                break;
            case 'machine':
                    SetListDataSingle('machine', response.data.machine);
                break;
            case 'mesa_part_service':
                    SetListDataSingle('mesa_part_service_data', response.data.mesa_part_service_data);
                    SetListDataSingle('mesa_part_service_buttons', response.data.mesa_part_service_buttons);
                break;
            case 'mesa_unimil':
                    SetListDataSingle('mesa_unimil', response.data.mesa_unimil);
                break;
            case 'expanded_portfolio':
                    SetListDataSingle('expanded_portfolio', response.data.expanded_portfolio);
                break;
            case 'mesa_upgrades':
                    SetListDataSingle('mesa_upgrades_data', response.data.mesa_upgrades_data);
                    SetListDataSingle('mesa_upgrades_buttons', response.data.mesa_upgrades_buttons);
                break;
        }
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Update Json
export function Reg_UpdateJson(CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'update_json');
    console.log('Teste 123');
    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Page - Lecture
export function Reg_Lecture(idPage, title, thematic, startDate, endDate, highlight, biography, newSpeaker, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();
    data.append('type_page', 'lecture');
    data.append('edit_data', 'edit_or_register');
    
    data.append("idPage", idPage);
    data.append("lec_title", title);
    data.append("lec_thematic", thematic);
    data.append("lec_startDate", startDate);
    data.append("lec_endDate", endDate);
    data.append("lec_highlight", highlight);
    data.append("lec_biography", biography);

    console.log(newSpeaker);
    newSpeaker.forEach((element, index) => {
        data.append("lec_speaker[]", element.value);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('lecture', response.data.lecture);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Page - Speaker
export function Reg_Speaker(idPage, highlight, name, office, contact, file, biography, newLecture, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();
    data.append('type_page', 'speaker');
    data.append('edit_data', 'edit_or_register');
    
    data.append("idPage", idPage);
    data.append("spe_highlight", highlight);
    data.append("spe_name", name);
    data.append("spe_office", office);
    data.append("spe_contact", contact);
    data.append("spe_file", file);
    data.append("spe_biography", biography);

    newLecture.forEach((element, index) => {
        data.append("spe_lecture[]", element.value);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('speaker', response.data.speaker);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Page - Machine
export function Reg_Machine(idPage, name, reference, title, imgExample, datasheet, thumbnail, link, obs, specialist, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data    = new FormData();
    data.append('type_page', 'machine');
    data.append('edit_data', 'edit_or_register');
    
    data.append("idPage", idPage);
    data.append("name", name);
    data.append("reference", reference);
    data.append("title", title);
    data.append("imgExample", imgExample);
    data.append("datasheet", datasheet);
    data.append("thumbnail", thumbnail);
    data.append("link", link);
    data.append("obs", obs);
    data.append("specialist", specialist);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('machine', response.data.machine);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}

// Edit user logado
export function Reg_EditUser(id, name, email, pass, file, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();

    data.append('type_page', 'access_dashboard');
    data.append('edit_data', 'edit_user');

    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('pass', pass);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('access_dashboard', response.data.access_dashboard);
        const newData = response.data.access_dashboard.find(item => item.id == id);
        cookies.set(typeCookieEmail, email, '/', cookiesRegister);
        if(pass !=''){
            cookies.set(typeCookiePassw, response.data.pass, '/', cookiesRegister);
        }
        SetUserData('id', newData['id']);
        SetUserData('file', newData['file']);
        SetUserData('name', newData['name']);
        SetUserData('email', newData['email']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit various data
export function Reg_VariousData(id, link, file, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'various_data');
    data.append('edit_data', 'various_data');

    data.append('idPage', id);
    data.append('link', link);
    data.append('file', file);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        CallbackSuccess();
        SetListDataSingle('various_data', response.data.various_data)

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit button mesa part service
export function Reg_ButtonPartService(img, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'mesa_part_service');
    data.append('edit_data', 'edit_button');
    img.forEach((element, index ) => {
        data.append('button_id[]', element.id);
        data.append('button_img_' + index, element.img);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('mesa_part_service_buttons', response.data.mesa_part_service_buttons);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit mesa part service
export function Reg_PartService(id, type, machine, position, stamp_1, stamp_2, qrCode, titlePtBr, titleEn, titleEs, imgQrCodePtBr, imgQrCodeEn, imgQrCodeEs, namePtBr, nameEn, nameEs, contents, highlight, subtitlePtBr, subtitleEn, subtitleEs, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'mesa_part_service');
    data.append('edit_data', 'edit_or_register_data');

    data.append('idPage', id);
    data.append('id_given', type);
    data.append('machine', machine);
    data.append('position', position);
    data.append('stamp_1', stamp_1);
    data.append('stamp_2', stamp_2);
    data.append('qrCode', qrCode);
    data.append('highlight', highlight);  

    data.append('namePtBr', namePtBr);
    data.append('nameEn', nameEn);
    data.append('nameEs', nameEs);

    data.append('titlePtBr', titlePtBr);
    data.append('titleEn', titleEn);
    data.append('titleEs', titleEs);

    data.append('subtitlePtBr', subtitlePtBr);
    data.append('subtitleEn', subtitleEn);
    data.append('subtitleEs', subtitleEs);

    data.append('imgQrCodePtBr', imgQrCodePtBr);
    data.append('imgQrCodeEn', imgQrCodeEn);
    data.append('imgQrCodeEs', imgQrCodeEs);
    
    contents.forEach((element, index) => {
        data.append('contents_id[]', element.id);
        data.append('contents_type[]', element.type);

        data.append('contents_text_pt_br[]', element.text_pt_br);
        data.append('contents_text_en[]', element.text_en);
        data.append('contents_text_es[]', element.text_es);

        data.append('contents_title_pt_br[]', element.title_pt_br);
        data.append('contents_title_en[]', element.title_en);
        data.append('contents_title_es[]', element.title_es);

        data.append('contents_video_img_pt_br_' + index, element.video_img_pt_br);
        data.append('contents_video_img_en_' + index, element.video_img_en);
        data.append('contents_video_img_es_' + index, element.video_img_es);
        
        data.append('contents_video_pt_br_' + index, element.video_pt_br);
        data.append('contents_video_en_' + index, element.video_en);
        data.append('contents_video_es_' + index, element.video_es);

        if(element.type == 'img'){
            element.img.forEach((element_1, index_1) => {
                data.append('contents_img_id[]', element_1.id);
                data.append('contents_img_index[]', index);
                
                data.append('contents_img_pt_br_' + index + '_' + index_1, element_1.img_pt_br);
                data.append('contents_img_en_' + index + '_' + index_1, element_1.img_en);
                data.append('contents_img_es_' + index + '_' + index_1, element_1.img_es);
            });
        }
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('mesa_part_service_data', response.data.mesa_part_service_data);
        CallbackSuccess();

    }).catch((error)=>{
        console.log(error);
        CallbackError();
    })
}

// Edit order - part-service
export function Reg_AltPositionPartService(type, id, order_){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'mesa_part_service');
    data.append('edit_data', 'edit_order_button');

    data.append('typeNewOrder', type);
    data.append('id', id);
    data.append('order_', order_);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('mesa_part_service_data', response.data.mesa_part_service_data);

    }).catch((error)=>{
    })
}

// Edit mesa upgrades
export function Reg_Upgrades(id, type, machine, position, stamp_1, stamp_2, qrCode, titlePtBr, titleEn, titleEs, imgQrCodePtBr, imgQrCodeEn, imgQrCodeEs, namePtBr, nameEn, nameEs, contents, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'mesa_upgrades');
    data.append('edit_data', 'edit_or_register_data');

    data.append('idPage', id);
    data.append('id_given', type);
    data.append('machine', machine);
    data.append('position', position);
    data.append('stamp_1', stamp_1);
    data.append('stamp_2', stamp_2);
    data.append('qrCode', qrCode);

    data.append('titlePtBr', titlePtBr);
    data.append('titleEn', titleEn);
    data.append('titleEs', titleEs);

    data.append('imgQrCodePtBr', imgQrCodePtBr);
    data.append('imgQrCodeEn', imgQrCodeEn);
    data.append('imgQrCodeEs', imgQrCodeEs);

    data.append('namePtBr', namePtBr);
    data.append('nameEn', nameEn);
    data.append('nameEs', nameEs);
    
    contents.forEach((element, index) => {
        data.append('contents_id[]', element.id);
        data.append('contents_type[]', element.type);

        data.append('contents_text_pt_br[]', element.text_pt_br);
        data.append('contents_text_en[]', element.text_en);
        data.append('contents_text_es[]', element.text_es);

        if(element.type == 'img'){
            element.img.forEach((element_1, index_1) => {
                data.append('contents_img_id[]', element_1.id);
                data.append('contents_img_index[]', index);

                data.append('contents_img_pt_br_' + index_1, element_1.img_pt_br);
                data.append('contents_img_en_' + index_1, element_1.img_en);
                data.append('contents_img_es_' + index_1, element_1.img_es);
            });
        }
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('mesa_upgrades_data', response.data.mesa_upgrades_data);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Edit button mesa part service
export function Reg_ButtonUpgrades(img, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'mesa_upgrades');
    data.append('edit_data', 'edit_button');
    img.forEach((element, index ) => {
        data.append('button_id[]', element.id);
        data.append('button_img_' + index, element.img);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('mesa_upgrades_buttons', response.data.mesa_upgrades_buttons);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit save - register data
export function Reg_RegisterUnimil(idPage, origin, btnTitle_1, btnSubtitle_1, btnImg_1, btnTexto_1, btnTitle_2, btnSubtitle_2, btnImg_2, btnTexto_2, btnTitle_3, btnSubtitle_3, btnImg_3, btnTexto_3, macSubtitle_1, macQrCode_1, macList, macSubtitle_2, macQrCode_2, macImgExample, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'mesa_unimil');
    data.append('edit_data', 'edit_or_register');
    
    data.append('idPage', idPage);
    data.append('origin', origin);
    data.append('btnTitle_1', btnTitle_1);
    data.append('btnSubtitle_1', btnSubtitle_1);
    data.append('btnImg_1', btnImg_1);
    data.append('btnTexto_1', btnTexto_1);
    data.append('btnTitle_2', btnTitle_2);
    data.append('btnSubtitle_2', btnSubtitle_2);
    data.append('btnImg_2', btnImg_2);
    data.append('btnTexto_2', btnTexto_2);
    data.append('btnTitle_3', btnTitle_3);
    data.append('btnSubtitle_3', btnSubtitle_3);
    data.append('btnTexto_3', btnTexto_3);
    
    // btnImg_3.forEach((element, index) => {
    //     data.append('btnImg_3_id[]', element.id);
    //     data.append('btnImg_3_img_' + index, element.img);
    // });

    data.append('macSubtitle_1', macSubtitle_1);
    data.append('macQrCode_1', macQrCode_1);
    data.append('macSubtitle_2', macSubtitle_2);
    data.append('macQrCode_2', macQrCode_2);
    data.append('macImgExample', macImgExample);
    
    macList.forEach((element, index) => {
        data.append('mac_list_1_id[]', element.id);
        data.append('mac_list_1_title[]', element.title);
        data.append('mac_list_1_subtitle[]', element.subtitle);
        data.append('mac_list_1_text[]', element.text);

        data.append('qtd_list[]', element.list.length);
        
        element.list.forEach((element_1, index_1) => {
            console.log(element_1);
            data.append('mac_list_1_mult_id[]', element_1.id);
            data.append('mac_list_1_mult_index[]', index);
            data.append('mac_list_1_mult_name[]', element_1.name);
            data.append('mac_list_1_mult_reference[]', element_1.reference);
            data.append('mac_list_1_img_' + index + '_' + index_1, element_1.img);
        });
    });  

    console.log(macList);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('mesa_unimil', response.data.mesa_unimil);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit save - operation center
export function Reg_OperationCenter(idPage, origin, titlePtBr, titleEn, titleEs, subtitlePtBr, subtitleEn, subtitleEs, position, stamp_1, stamp_2, data_1, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'mesa_operation_center');
    data.append('edit_data', 'edit_or_register_data');
    
    data.append('idPage', idPage);
    data.append('origin', origin);

    data.append('titlePtBr', titlePtBr);
    data.append('titleEn', titleEn);
    data.append('titleEs', titleEs);

    data.append('subtitlePtBr', subtitlePtBr);  
    data.append('subtitleEn', subtitleEn);  
    data.append('subtitleEs', subtitleEs);  

    data.append('position', position); 
    data.append('stamp_1', stamp_1); 
    data.append('stamp_2', stamp_2);  
    
    console.log(position, stamp_1, stamp_2);

    data_1.forEach((element, index) => {
        data.append('data_1_id[]', element.id);
        data.append('data_1_type[]', element.type);

        data.append('data_1_text_pt_br[]', element.text_pt_br);
        data.append('data_1_text_en[]', element.text_en);
        data.append('data_1_text_es[]', element.text_es);

        data.append('data_1_link_pt_br[]', element.link_pt_br);
        data.append('data_1_link_en[]', element.link_en);
        data.append('data_1_link_es[]', element.link_es);

        data.append('data_1_img_pt_br_' + index, element.file_pt_br);
        data.append('data_1_img_en_' + index, element.file_en);
        data.append('data_1_img_es_' + index, element.file_es);

        data.append('data_1_video_pt_br_' + index, element.video_pt_br);
        data.append('data_1_video_en_' + index, element.video_en);
        data.append('data_1_video_es_' + index, element.video_es);
    }); 

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log('teste 1');
        console.log(response.data);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('mesa_operation_center', response.data.mesa_operation_center);
        CallbackSuccess();

    }).catch((error)=>{
        console.log('teste 2');
        CallbackError();
    })
}
// Edit button mesa operations
export function Reg_ButtonOperations(img, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'mesa_operation_center');
    data.append('edit_data', 'edit_button');
    img.forEach((element, index ) => {
        data.append('button_id[]', element.id);
        data.append('button_img_' + index, element.img);
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('mesa_operation_center_btn', response.data.mesa_operation_center_btn);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit expanded portfolio
export function Reg_ExpandedPortfolio(idioma, contents, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'expanded_portfolio');
    data.append('edit_data', 'edit_data');
    
    data.append('idioma', idioma);

    contents.forEach((element, index) => {
        element.contents.forEach((eleme_1, index_1) => {
            data.append('pt_br_id[]', eleme_1.id);
            data.append('pt_br_text[]', eleme_1.text);
            data.append('pt_br_title[]', eleme_1.title);
            data.append('pt_br_text_1[]', eleme_1.text_1);
            data.append('pt_br_title_1[]', eleme_1.title_1);
            data.append('pt_br_btn_1[]', eleme_1.btn_1);
            data.append('pt_br_btn_2[]', eleme_1.btn_2);
            data.append('pt_br_btn_3[]', eleme_1.btn_3);
            data.append('pt_br_btn_4[]', eleme_1.btn_4);

            data.append('pt_br_img_' + index, eleme_1.img);
            data.append('pt_br_video_' + index, eleme_1.video);
            data.append('pt_br_img_bg_' + index, eleme_1.img_bg);

            eleme_1.data_btn.forEach((elent_2, index_2)=>{
                console.log(elent_2);
                data.append('btn_index[]', index_1);
                data.append('btn_index_1[]', index_2);

                data.append('btn_id[]', elent_2.id);
                data.append('btn_id_given[]', elent_2.id_given);
                data.append('btn_name[]', elent_2.name);
                data.append('btn_text[]', elent_2.text);

                elent_2.gallery.forEach((elent_3, index_3)=>{
                    data.append('btn_gallery_id[]', elent_3.id);
                    data.append('btn_gallery_id_given[]', elent_3.id_given);
                    data.append('btn_gallery_index[]', index_3);

                    data.append('btn_gallery_file_' + elent_3.id_given + '_' + index_3, elent_3.file);

                    data.append('btn_gallery_code[]', elent_3.code);
                    data.append('btn_gallery_code_original[]', elent_3.code_original);
                    data.append('btn_gallery_applications[]', elent_3.applications);
                    data.append('btn_gallery_text[]', elent_3.text);
                })
            })

            eleme_1.gallery.forEach((elent_2, index_2)=>{
                data.append('pt_br_id_gallery[]', elent_2.id);
                data.append('pt_br_index[]', index_1);
                data.append('pt_br_index_1[]', index_2);
                data.append('pt_br_id_given[]', elent_2.id_given);
                data.append('pt_br_name[]', elent_2.name);
                data.append('pt_br_file[]', elent_2.file);
                data.append('pt_br_img_example[]', elent_2.img_example);
            })
        })
    });

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('expanded_portfolio', response.data.expanded_portfolio);
        CallbackSuccess();

    }).catch((error)=>{
        console.log(error);
        CallbackError();
    })
}

// Copy data
export function Reg_Copy(idPage, origin, type, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', origin);
    data.append('edit_data', type);
    data.append('idPage', idPage);

    Axios({
        url     : process.env.REACT_APP_API_URL + 'php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        console.log(response.data);
        console.log(origin);
        cookies.set(typeCookiePageId, response.data.idPage, '/', cookiesRegister);
        SetListPag('currentPageId', response.data.idPage);
        switch (origin) {
            case "mesa_part_service":
                    SetListDataSingle('mesa_part_service_data', response.data.mesa_part_service_data);                
                break;
        }

        CallbackSuccess();

    }).catch((error)=>{
        console.log(error);
        CallbackError();
    })
}