import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_ExpandedPortfolio, Reg_OperationCenter, Reg_PartService } from "services/RegisterData";

import { SvgAddData, SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgTable, SvgVideo } from "components/SvgFile";

export default function Page_ExpandedPortfolio_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ idUser, setIdUser ]     = useState(GetUserData("id"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('expanded_portfolio'));

    const [ showData, setShowData ] = useState(InitialData('data'));
    
    const [ textFixed, setTextFixed ] = useState({
        "text_1": {
            "pt_br": "Dados da mesa - Operations Center",
            "en": "Table Data - Operations Center",
            "es": "Tabla de Datos - Centro de Operaciones"
        },
        "text_2": {
            "pt_br": "Origem",
            "en": "Origin",
            "es": "Origen"
        },
        "text_3": {
            "pt_br": "Posição",
            "en": "Position",
            "es": "Posición"
        },
        "text_4": {
            "pt_br": "Selo garantia",
            "en": "Guarantee seal",
            "es": "Sello de garantía"
        },
        "text_5": {
            "pt_br": "Selo financiamento",
            "en": "Financing stamp",
            "es": "Sello de financiacion"
        },
        "text_8": {
            "pt_br": "Conteúdo",
            "en": "Content",
            "es": "Contenido"
        },
        "text_9": {
            "pt_br": "Tipo",
            "en": "Type",
            "es": "Tipo"
        },
        "text_10": {
            "pt_br": "Imagem",
            "en": "Image",
            "es": "Imagen"
        },
        "text_11": {
            "pt_br": "Texto",
            "en": "Text",
            "es": "Texto"
        },
        "text_12": {
            "pt_br": "Adicionar imagem",
            "en": "Add image",
            "es": "Añadir imagen"
        },
        "text_13": {
            "pt_br": "Imagem adicionada",
            "en": "Image added",
            "es": "Imagen añadida"
        },
        "text_14": {
            "pt_br": "Adicionar vídeo",
            "en": "Add video",
            "es": "Añadir video"
        },
        "text_15": {
            "pt_br": "Vídeo adicionada",
            "en": "Video added",
            "es": "Vídeo añadido"
        }
    });

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage('expanded_portfolio', setDataPage);
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('expanded_portfolio'));
        setShowData(InitialData('data'));
    }, [dataPage]);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('expanded_portfolio'));
    }, [idPage]);

    function InitialData(){
        const typePage   = idPage.split('/');
        const newData    = dataPage.filter(item => item.idioma == typePage[1]);
        const returnData = newData.filter(item => item.origin_ == typePage[0]);
        return returnData;
    }

    function HandleData(type, index, index_1, value){
        const newData = [...showData];
        if(type == 'img'){
            if(value){
                newData[index]['contents'][index_1]['img']       = value;
                newData[index]['contents'][index_1]['statusImg'] = true;
            }else {
                newData[index]['contents'][index_1]['img']       = "";
                newData[index]['contents'][index_1]['statusImg'] = false;
            }

        }else if(type == 'img_bg'){
            if(value){
                newData[index]['contents'][index_1]['img_bg']      = value;
                newData[index]['contents'][index_1]['statusImgBg'] = true;
            }else {
                newData[index]['contents'][index_1]['img_bg']      = "";
                newData[index]['contents'][index_1]['statusImgBg'] = false;
            }

        }else if(type == 'video'){
            if(value){
                newData[index]['contents'][index_1]['video']       = value;
                newData[index]['contents'][index_1]['statusVideo'] = true;
            }else {
                newData[index]['contents'][index_1]['video']       = "";
                newData[index]['contents'][index_1]['statusVideo'] = false;
            }

        }else {
            newData[index]['contents'][index_1][type] = value;
        }
        setShowData(newData);
    }

    function HandleDataGalery(type, index, index_1, index_2, value){
        const newData = [...showData];
        if(type == 'file'){
            if(value){
                newData[index]['contents'][index_1]['gallery'][index_2]['file']   = value;
                newData[index]['contents'][index_1]['gallery'][index_2]['status'] = true;
            }else {
                newData[index]['contents'][index_1]['gallery'][index_2]['file']   = "";
                newData[index]['contents'][index_1]['gallery'][index_2]['status'] = false;
            }

        }else if(type == 'img_example'){
            if(value){
                newData[index]['contents'][index_1]['gallery'][index_2]['img_example']   = value;
                newData[index]['contents'][index_1]['gallery'][index_2]['statusExample'] = true;
            }else {
                newData[index]['contents'][index_1]['gallery'][index_2]['img_example']   = "";
                newData[index]['contents'][index_1]['gallery'][index_2]['statusExample'] = false;
            }

        }else {
            newData[index]['contents'][index_1]['gallery'][index_2][type] = value;
        }
        setShowData(newData);
    }

    function AddNewImg(id, index, index_1){
        const newData = [...showData];
        newData[index]['contents'][index_1]['gallery'].push({ "id": 0, "id_given": id, "name": "", "file": "", "status": false, "img_example": "", "statusExample": false, "code": "", "code_original": "", "applications": "", "text": "" });
        setShowData(newData);
    }

    function DeleteData(id, index, index_1, index_2){
        if(id == 0){
            const newData = [...showData];
            newData[index]['contents'][index_1]['gallery'].splice(index_2, 1);
            setShowData(newData)
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "expanded_portfolio", "type": "delete_data_img", "name": "Imagem" });
            SetModalState('Confirmation', true);
        }
    }

    function ReturnPage(){
        SetListPag('currentPage', 'expanded_portfolio');
        SetListPag('currentPageId', 'remuve');
    }

    function ShowData_PtBr(id, index, index_1, data){
        switch (id) {
            case 1:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusVideo == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusVideo == true ? "Vídeo adicionado" : "Adicionar vídeo" }
                                        <input type="file" onChange={ (e)=>{ HandleData('video', index, index_1, e.target.files[0]); } } accept="video/*" />
                                    </label>
                                </div>
                                {
                                data.video == '' ? null : 
                                    data.statusVideo == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showVideo_local', URL.createObjectURL(data.video)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showVideo', "mesa/expanded_portfolio/" + data.video) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                }
                            </span>
                        </div>
                        <div className="div_text">
                            <div className="show_contents">
                                <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                            </div>
                        </div>
                    </div>
                )
            
            case 2:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                            <span className="name_input">Título</span>
                        </div>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem botão adicionado" : "Adicionar imagem botão" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                data.img == '' ? null : 
                                    data.statusImg == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "Imagem maquinas adicionado" : "Adicionar imagem maquinas" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                data.img_bg == '' ? null : 
                                    data.statusImgBg == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                }
                            </span>
                        </div>                         
                        {/* 
                        <div className="list_input_data" style={ { marginTop: 0 } }>
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('btn_1', index, index_1, e.target.value) } } value={ data.btn_1 } maxLength="40"/>
                                <span className="name_input">Botão 1</span>
                            </div>
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('btn_2', index, index_1, e.target.value) } } value={ data.btn_2 } maxLength="40"/>
                                <span className="name_input">Botão 2</span>
                            </div>
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('btn_3', index, index_1, e.target.value) } } value={ data.btn_3 } maxLength="40"/>
                                <span className="name_input">Botão 3</span>
                            </div>
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('btn_4', index, index_1, e.target.value) } } value={ data.btn_4 } maxLength="40"/>
                                <span className="name_input">Botão 4</span>
                            </div>
                        </div> 
                        <div className="div_border_data">
                            <div className="list_input_data" onClick={ ()=>{ AddNewImg(data.id, index, index_1) }} style={ { justifyContent: "flex-end", marginTop: 0, borderBottom: "2px solid #ebebeb", paddingBottom: "10px" } }>
                                <div className="div_input new_data_page" style={ { marginTop: 0 } }>Adicionar nova foto</div>
                            </div>
                            {
                                data.gallery.map((key_2, index_2)=>{
                                    return(
                                        <div className="list_input_data" key={ index_2 }>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataGalery('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="40"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('file', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.file == '' ? null : 
                                                        key_2.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input" onClick={ ()=>{ DeleteData(key_2.id, index, index_1, index_2) } }>
                                                <SvgDelete color="#F00000" className="icons" />
                                            </div>
                                        </div>  
                                    )
                                })
                            }
                        </div> 
                        */}
                    </div>
                )
                
            case 3:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                            <span className="name_input">Título</span>
                        </div>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null :
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "QrCode adicionado" : "Adicionar QrCode" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null : 
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div> 
                    </div>
                )
                                                        
            case 4:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem na esquerda adicionada" : "Adicionar imagem na esquerda" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "Imagem na direita adicionado" : "Adicionar imagem na direita" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null :
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title_1', index, index_1, e.target.value) } } value={ data.title_1 } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    function ShowData_Es(id, index, index_1, data){
        switch (id) {
            case 5:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem na esquerda adicionada" : "Adicionar imagem na esquerda" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "Imagem na direita adicionada" : "Adicionar imagem na direita" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null : 
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>
                    </div>
                )

            case 6:
                return(                    
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            {/* <div className="list_input_data" onClick={ ()=>{ AddNewImg(data.id, index, index_1) }} style={ { justifyContent: "flex-end", marginTop: 0, borderBottom: "2px solid #ebebeb", paddingBottom: "10px" } }>
                                <div className="div_input new_data_page" style={ { marginTop: 0 } }>Adicionar logo</div>
                            </div> */}
                            {
                                data.gallery.map((key_2, index_2)=>{
                                    return(
                                        <div className="list_input_data" key={ index_2 }>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.status == true ? "Logo adicionado" : "Adicionar logo" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('file', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.file == '' ? null : 
                                                        key_2.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataGalery('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="40"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.statusExample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.statusExample == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('img_example', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.img_example == '' ? null : 
                                                        key_2.statusExample == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.img_example)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.img_example) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input" onClick={ ()=>{ DeleteData(key_2.id, index, index_1, index_2) } }>
                                                <SvgDelete color="#F00000" className="icons" />
                                            </div>
                                        </div>  
                                    )
                                })
                            }
                        </div>
                        <div className="div_text">
                            <div className="show_contents">
                                <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                            </div>
                        </div>
                    </div>
                )

            case 7:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title_1', index, index_1, e.target.value) } } value={ data.title_1 } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )

            case 8:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            {/* <div className="list_input_data" onClick={ ()=>{ AddNewImg(data.id, index, index_1) }} style={ { justifyContent: "flex-end", marginTop: 0, borderBottom: "2px solid #ebebeb", paddingBottom: "10px" } }>
                                <div className="div_input new_data_page" style={ { marginTop: 0 } }>Adicionar logo</div>
                            </div> */}
                            {
                                data.gallery.map((key_2, index_2)=>{
                                    return(
                                        <div className="list_input_data" key={ index_2 }>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.status == true ? "Logo adicionado" : "Adicionar logo" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('file', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.file == '' ? null : 
                                                        key_2.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataGalery('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="40"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.statusExample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.statusExample == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('img_example', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.img_example == '' ? null : 
                                                        key_2.statusExample == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.img_example)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.img_example) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input" onClick={ ()=>{ DeleteData(key_2.id, index, index_1, index_2) } }>
                                                <SvgDelete color="#F00000" className="icons" />
                                            </div>
                                        </div>  
                                    )
                                })
                            }
                        </div>
                    </div>
                )

            case 9:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>  
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }        
    }

    function ShowData_En(id, index, index_1, data){
        switch (id) {
            case 10:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem na esquerda adicionada" : "Adicionar imagem na esquerda" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "Imagem na direita adicionada" : "Adicionar imagem na direita" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null : 
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>
                    </div>
                )

            case 11:
                return(                    
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            {/* <div className="list_input_data" onClick={ ()=>{ AddNewImg(data.id, index, index_1) }} style={ { justifyContent: "flex-end", marginTop: 0, borderBottom: "2px solid #ebebeb", paddingBottom: "10px" } }>
                                <div className="div_input new_data_page" style={ { marginTop: 0 } }>Adicionar logo</div>
                            </div> */}
                            {
                                data.gallery.map((key_2, index_2)=>{
                                    return(
                                        <div className="list_input_data" key={ index_2 }>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.status == true ? "Logo adicionado" : "Adicionar logo" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('file', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.file == '' ? null : 
                                                        key_2.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataGalery('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="40"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.statusExample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.statusExample == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('img_example', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.img_example == '' ? null : 
                                                        key_2.statusExample == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.img_example)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.img_example) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input" onClick={ ()=>{ DeleteData(key_2.id, index, index_1, index_2) } }>
                                                <SvgDelete color="#F00000" className="icons" />
                                            </div>
                                        </div>  
                                    )
                                })
                            }
                        </div>
                        <div className="div_text">
                            <div className="show_contents">
                                <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                            </div>
                        </div>
                    </div>
                )

            case 12:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title_1', index, index_1, e.target.value) } } value={ data.title_1 } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )

            case 13:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            {/* <div className="list_input_data" onClick={ ()=>{ AddNewImg(data.id, index, index_1) }} style={ { justifyContent: "flex-end", marginTop: 0, borderBottom: "2px solid #ebebeb", paddingBottom: "10px" } }>
                                <div className="div_input new_data_page" style={ { marginTop: 0 } }>Adicionar logo</div>
                            </div> */}
                            {
                                data.gallery.map((key_2, index_2)=>{
                                    return(
                                        <div className="list_input_data" key={ index_2 }>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.status == true ? "Logo adicionado" : "Adicionar logo" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('file', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.file == '' ? null : 
                                                        key_2.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataGalery('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="40"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.statusExample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.statusExample == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('img_example', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.img_example == '' ? null : 
                                                        key_2.statusExample == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.img_example)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.img_example) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input" onClick={ ()=>{ DeleteData(key_2.id, index, index_1, index_2) } }>
                                                <SvgDelete color="#F00000" className="icons" />
                                            </div>
                                        </div>  
                                    )
                                })
                            }
                        </div>
                    </div>
                )

            case 14:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>  
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    function AddNewPortfolio(id, index, index_1, index_2){
        const newData = [...showData];
        newData[index]['contents'][index_1]['data_btn'][index_2]['gallery'].push({ 
            "id": 0, 
            "id_given": id,
            "file": "",
            "status": false,
            "code": "",
            "code_original": "",
            "applications": "",
            "text": ""
        });
        setShowData(newData);
    }

    function HandleDataBtn(type, index, index_1, index_2, value){
        const newData = [...showData];        
        newData[index]['contents'][index_1].data_btn[index_2][type] = value;
        setShowData(newData);
    }

    function HandleDataBtnGallery(type, index, index_1, index_2, index_3, value){
        const newData = [...showData];    
        if(type == 'file'){
            if(value){
                newData[index]['contents'][index_1].data_btn[index_2].gallery[index_3]['file']   = value;
                newData[index]['contents'][index_1].data_btn[index_2].gallery[index_3]['status'] = true;
            }else {
                newData[index]['contents'][index_1].data_btn[index_2].gallery[index_3]['file']   = "";
                newData[index]['contents'][index_1].data_btn[index_2].gallery[index_3]['status'] = false;
            }
        }else {
            newData[index]['contents'][index_1].data_btn[index_2].gallery[index_3][type] = value;
        }
        setShowData(newData);
    }

    function DeleteDataGallery(id, index, index_1, index_2, index_3){
        if(id == 0){
            const newData = [...showData];
            newData[index]['contents'][index_1].data_btn[index_2].gallery.splice(index_3, 1);
            setShowData(newData)
        }else {
            SetModalData("Confirmation", { "id": id, "origin": "expanded_portfolio", "type": "delete_data_img_gallery", "name": "Imagem" });
            SetModalState('Confirmation', true);
        }
    }

    function GrainHarvest_PtBr(id, index, index_1, data){
        switch (id) {
            case 25:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusVideo == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusVideo == true ? "Vídeo adicionado" : "Adicionar vídeo" }
                                        <input type="file" onChange={ (e)=>{ HandleData('video', index, index_1, e.target.files[0]); } } accept="video/*" />
                                    </label>
                                </div>
                                {
                                data.video == '' ? null : 
                                    data.statusVideo == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showVideo_local', URL.createObjectURL(data.video)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showVideo', "mesa/expanded_portfolio/" + data.video) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                }
                            </span>
                        </div>
                        <div className="div_text">
                            <div className="show_contents">
                                <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                            </div>
                        </div>
                    </div>
                )
                
            case 26:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                            <span className="name_input">Título</span>
                        </div>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null :
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "QrCode adicionado" : "Adicionar QrCode" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null : 
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div> 
                    </div>
                )
                                                        
            case 27:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem na esquerda adicionada" : "Adicionar imagem na esquerda" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "Imagem na direita adicionado" : "Adicionar imagem na direita" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null :
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title_1', index, index_1, e.target.value) } } value={ data.title_1 } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            
            // portfólio
            case 28:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        {
                            data.data_btn.map((key_2, index_2)=>{
                                return(
                                    <div className="div_border_data" key={ index_2 }>
                                        <div className="list_input_data" key={ index_2 }>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataBtn('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="255"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <div className="div_text">
                                                <div className="show_contents">
                                                    <JoditEditor config={ config } value={ key_2.text == '' ? null : key_2.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleDataBtn('text', index, index_1, index_2, newContent) } />
                                                </div>
                                            </div>
                                            <div className="list_input_data" style={ { justifyContent: "flex-end", width: "-webkit-fill-available" } }>
                                                <div className="div_input new_data_page" style={ { marginTop: 0 } } onClick={ ()=>{ AddNewPortfolio(key_2.id, index, index_1, index_2) }}>Adicionar nova foto</div>
                                            </div>
                                            {
                                                key_2.gallery.map((key_3, index_3)=>{
                                                    return(
                                                        <div className="list_input_data div_data_add" key={ index_3 }>
                                                            <div className="div_input space_div">
                                                                <input type="text" onChange={ (e)=>{ HandleDataBtnGallery('code', index, index_1, index_2, index_3, e.target.value) } } value={ key_3.code } maxLength="255"/>
                                                                <span className="name_input">Código Alternativo</span>
                                                            </div>
                                                            <div className="div_input space_div">
                                                                <input type="text" onChange={ (e)=>{ HandleDataBtnGallery('code_original', index, index_1, index_2, index_3, e.target.value) } } value={ key_3.code_original } maxLength="255"/>
                                                                <span className="name_input">Código Original</span>
                                                            </div>
                                                            <div className="div_input space_div">
                                                                <input type="text" onChange={ (e)=>{ HandleDataBtnGallery('applications', index, index_1, index_2, index_3, e.target.value) } } value={ key_3.applications } maxLength="255"/>
                                                                <span className="name_input">Aplicação</span>
                                                            </div>
                                                            <span className="div_input div_add_img">
                                                                <div className={ key_3.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                    <label>
                                                                        { key_3.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                                        <input type="file" onChange={ (e)=>{ HandleDataBtnGallery('file', index, index_1, index_2, index_3, e.target.files[0]); } } accept="image/*" />
                                                                    </label>
                                                                </div>
                                                                {
                                                                    key_3.file == '' ? null : 
                                                                        key_3.status == true ?
                                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_3.file)) } }>
                                                                            <SvgImg color="#324d6b" className="icons"/>
                                                                        </div> :
                                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_3.file) } }>
                                                                            <SvgImg color="#324d6b" className="icons"/>
                                                                        </div>
                                                                }
                                                            </span>
                                                            <div className="div_input" onClick={ ()=>{ DeleteDataGallery(key_3.id, index, index_1, index_2, index_3) } }>
                                                                <SvgDelete color="#F00000" className="icons" />
                                                            </div>
                                                            <div className="div_text">
                                                                <div className="show_contents">
                                                                    <JoditEditor config={ config } value={ key_3.text == '' ? null : key_3.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleDataBtnGallery('text', index, index_1, index_2, index_3, newContent) } />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>  
                                    </div> 
                                )
                            })
                        }
                    </div>
                )
        }
    }

    function GrainHarvest_En(id, index, index_1, data){
        switch (id) {
            case 29:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem na esquerda adicionada" : "Adicionar imagem na esquerda" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "Imagem na direita adicionada" : "Adicionar imagem na direita" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null : 
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>
                    </div>
                )

            case 30:
                return(                    
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            {/* <div className="list_input_data" onClick={ ()=>{ AddNewImg(data.id, index, index_1) }} style={ { justifyContent: "flex-end", marginTop: 0, borderBottom: "2px solid #ebebeb", paddingBottom: "10px" } }>
                                <div className="div_input new_data_page" style={ { marginTop: 0 } }>Adicionar logo</div>
                            </div> */}
                            {
                                data.gallery.map((key_2, index_2)=>{
                                    return(
                                        <div className="list_input_data" key={ index_2 }>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.status == true ? "Logo adicionado" : "Adicionar logo" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('file', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.file == '' ? null : 
                                                        key_2.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataGalery('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="40"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.statusExample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.statusExample == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('img_example', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.img_example == '' ? null : 
                                                        key_2.statusExample == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.img_example)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.img_example) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input" onClick={ ()=>{ DeleteData(key_2.id, index, index_1, index_2) } }>
                                                <SvgDelete color="#F00000" className="icons" />
                                            </div>
                                        </div>  
                                    )
                                })
                            }
                        </div>
                        <div className="div_text">
                            <div className="show_contents">
                                <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                            </div>
                        </div>
                    </div>
                )

            case 31:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title_1', index, index_1, e.target.value) } } value={ data.title_1 } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )

            case 32:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>  
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
        } 
    }

    function GrainHarvest_Es(id, index, index_1, data){
        switch (id) {
            case 33:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem na esquerda adicionada" : "Adicionar imagem na esquerda" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "Imagem na direita adicionada" : "Adicionar imagem na direita" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null : 
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>
                    </div>
                )

            case 34:
                return(                    
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            {/* <div className="list_input_data" onClick={ ()=>{ AddNewImg(data.id, index, index_1) }} style={ { justifyContent: "flex-end", marginTop: 0, borderBottom: "2px solid #ebebeb", paddingBottom: "10px" } }>
                                <div className="div_input new_data_page" style={ { marginTop: 0 } }>Adicionar logo</div>
                            </div> */}
                            {
                                data.gallery.map((key_2, index_2)=>{
                                    return(
                                        <div className="list_input_data" key={ index_2 }>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.status == true ? "Logo adicionado" : "Adicionar logo" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('file', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.file == '' ? null : 
                                                        key_2.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataGalery('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="40"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.statusExample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.statusExample == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('img_example', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.img_example == '' ? null : 
                                                        key_2.statusExample == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.img_example)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.img_example) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input" onClick={ ()=>{ DeleteData(key_2.id, index, index_1, index_2) } }>
                                                <SvgDelete color="#F00000" className="icons" />
                                            </div>
                                        </div>  
                                    )
                                })
                            }
                        </div>
                        <div className="div_text">
                            <div className="show_contents">
                                <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                            </div>
                        </div>
                    </div>
                )

            case 35:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title_1', index, index_1, e.target.value) } } value={ data.title_1 } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }  
    }

    function UtilityTractor_PtBr(id, index, index_1, data){
        switch (id) {
            case 15:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusVideo == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusVideo == true ? "Vídeo adicionado" : "Adicionar vídeo" }
                                        <input type="file" onChange={ (e)=>{ HandleData('video', index, index_1, e.target.files[0]); } } accept="video/*" />
                                    </label>
                                </div>
                                {
                                data.video == '' ? null : 
                                    data.statusVideo == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showVideo_local', URL.createObjectURL(data.video)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showVideo', "mesa/expanded_portfolio/" + data.video) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                }
                            </span>
                        </div>
                        <div className="div_text">
                            <div className="show_contents">
                                <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                            </div>
                        </div>
                    </div>
                )
                
            case 16:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                            <span className="name_input">Título</span>
                        </div>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem adicionada" : "Adicionar imagem" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null :
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "QrCode adicionado" : "Adicionar QrCode" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null : 
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div> 
                    </div>
                )
                                                        
            case 18:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem na esquerda adicionada" : "Adicionar imagem na esquerda" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "Imagem na direita adicionado" : "Adicionar imagem na direita" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null :
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title_1', index, index_1, e.target.value) } } value={ data.title_1 } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            
            // portfolio
            case 17:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        {
                            data.data_btn.map((key_2, index_2)=>{
                                return(
                                    <div className="div_border_data" key={ index_2 }>
                                        <div className="list_input_data" key={ index_2 }>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataBtn('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="255"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <div className="div_text">
                                                <div className="show_contents">
                                                    <JoditEditor config={ config } value={ key_2.text == '' ? null : key_2.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleDataBtn('text', index, index_1, index_2, newContent) } />
                                                </div>
                                            </div>
                                            <div className="list_input_data" style={ { justifyContent: "flex-end", width: "-webkit-fill-available" } }>
                                                <div className="div_input new_data_page" style={ { marginTop: 0 } } onClick={ ()=>{ AddNewPortfolio(key_2.id, index, index_1, index_2) }}>Adicionar nova foto</div>
                                            </div>
                                            {
                                                key_2.gallery.map((key_3, index_3)=>{
                                                    return(
                                                        <div className="list_input_data div_data_add" key={ index_3 }>
                                                            <div className="div_input space_div">
                                                                <input type="text" onChange={ (e)=>{ HandleDataBtnGallery('code', index, index_1, index_2, index_3, e.target.value) } } value={ key_3.code } maxLength="255"/>
                                                                <span className="name_input">Código Alternativo</span>
                                                            </div>
                                                            <div className="div_input space_div">
                                                                <input type="text" onChange={ (e)=>{ HandleDataBtnGallery('code_original', index, index_1, index_2, index_3, e.target.value) } } value={ key_3.code_original } maxLength="255"/>
                                                                <span className="name_input">Código Original</span>
                                                            </div>
                                                            <div className="div_input space_div">
                                                                <input type="text" onChange={ (e)=>{ HandleDataBtnGallery('applications', index, index_1, index_2, index_3, e.target.value) } } value={ key_3.applications } maxLength="255"/>
                                                                <span className="name_input">Aplicação</span>
                                                            </div>
                                                            <span className="div_input div_add_img">
                                                                <div className={ key_3.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                    <label>
                                                                        { key_3.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                                        <input type="file" onChange={ (e)=>{ HandleDataBtnGallery('file', index, index_1, index_2, index_3, e.target.files[0]); } } accept="image/*" />
                                                                    </label>
                                                                </div>
                                                                {
                                                                    key_3.file == '' ? null : 
                                                                        key_3.status == true ?
                                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_3.file)) } }>
                                                                            <SvgImg color="#324d6b" className="icons"/>
                                                                        </div> :
                                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_3.file) } }>
                                                                            <SvgImg color="#324d6b" className="icons"/>
                                                                        </div>
                                                                }
                                                            </span>
                                                            <div className="div_input" onClick={ ()=>{ DeleteDataGallery(key_3.id, index, index_1, index_2, index_3) } }>
                                                                <SvgDelete color="#F00000" className="icons" />
                                                            </div>
                                                            <div className="div_text">
                                                                <div className="show_contents">
                                                                    <JoditEditor config={ config } value={ key_3.text == '' ? null : key_3.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleDataBtnGallery('text', index, index_1, index_2, index_3, newContent) } />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>  
                                    </div> 
                                )
                            })
                        }
                    </div>
                )
        }
    }

    function UtilityTractor_En(id, index, index_1, data){
        switch (id) {
            case 22:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem na esquerda adicionada" : "Adicionar imagem na esquerda" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "Imagem na direita adicionada" : "Adicionar imagem na direita" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null : 
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>
                    </div>
                )

            case 23:
                return(                    
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            {/* <div className="list_input_data" onClick={ ()=>{ AddNewImg(data.id, index, index_1) }} style={ { justifyContent: "flex-end", marginTop: 0, borderBottom: "2px solid #ebebeb", paddingBottom: "10px" } }>
                                <div className="div_input new_data_page" style={ { marginTop: 0 } }>Adicionar logo</div>
                            </div> */}
                            {
                                data.gallery.map((key_2, index_2)=>{
                                    return(
                                        <div className="list_input_data" key={ index_2 }>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.status == true ? "Logo adicionado" : "Adicionar logo" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('file', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.file == '' ? null : 
                                                        key_2.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataGalery('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="40"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.statusExample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.statusExample == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('img_example', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.img_example == '' ? null : 
                                                        key_2.statusExample == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.img_example)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.img_example) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input" onClick={ ()=>{ DeleteData(key_2.id, index, index_1, index_2) } }>
                                                <SvgDelete color="#F00000" className="icons" />
                                            </div>
                                        </div>  
                                    )
                                })
                            }
                        </div>
                        <div className="div_text">
                            <div className="show_contents">
                                <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                            </div>
                        </div>
                    </div>
                )

            case 24:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title_1', index, index_1, e.target.value) } } value={ data.title_1 } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    function UtilityTractor_Es(id, index, index_1, data){ 
        switch (id) {
            case 19:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImg == true ? "Imagem na esquerda adicionada" : "Adicionar imagem na esquerda" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img == '' ? null : 
                                        data.statusImg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>  
                        <div className="show_contents">
                            <span className="div_input div_add_img">
                                <div className={ data.statusImgBg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                    <label>
                                        { data.statusImgBg == true ? "Imagem na direita adicionada" : "Adicionar imagem na direita" }
                                        <input type="file" onChange={ (e)=>{ HandleData('img_bg', index, index_1, e.target.files[0]); } } accept="image/*" />
                                    </label>
                                </div>
                                {
                                    data.img_bg == '' ? null : 
                                        data.statusImgBg == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(data.img_bg)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + data.img_bg) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                }
                            </span>
                        </div>
                    </div>
                )

            case 20:
                return(                    
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            {/* <div className="list_input_data" onClick={ ()=>{ AddNewImg(data.id, index, index_1) }} style={ { justifyContent: "flex-end", marginTop: 0, borderBottom: "2px solid #ebebeb", paddingBottom: "10px" } }>
                                <div className="div_input new_data_page" style={ { marginTop: 0 } }>Adicionar logo</div>
                            </div> */}
                            {
                                data.gallery.map((key_2, index_2)=>{
                                    return(
                                        <div className="list_input_data" key={ index_2 }>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.status == true ? "Logo adicionado" : "Adicionar logo" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('file', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.file == '' ? null : 
                                                        key_2.status == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.file) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input space_div">
                                                <input type="text" onChange={ (e)=>{ HandleDataGalery('name', index, index_1, index_2, e.target.value) } } value={ key_2.name } maxLength="40"/>
                                                <span className="name_input">Nome</span>
                                            </div>
                                            <span className="div_input div_add_img">
                                                <div className={ key_2.statusExample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { key_2.statusExample == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ HandleDataGalery('img_example', index, index_1, index_2, e.target.files[0]); } } accept="image/*" />
                                                    </label>
                                                </div>
                                                {
                                                    key_2.img_example == '' ? null : 
                                                        key_2.statusExample == true ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_2.img_example)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div> :
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/expanded_portfolio/" + key_2.img_example) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                }
                                            </span>
                                            <div className="div_input" onClick={ ()=>{ DeleteData(key_2.id, index, index_1, index_2) } }>
                                                <SvgDelete color="#F00000" className="icons" />
                                            </div>
                                        </div>  
                                    )
                                })
                            }
                        </div>
                        <div className="div_text">
                            <div className="show_contents">
                                <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                            </div>
                        </div>
                    </div>
                )

            case 21:
                return(
                    <div className="list_input_data" key={ index_1 }>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title', index, index_1, e.target.value) } } value={ data.title } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>                              
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text == '' ? null : data.text.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                        <div className="div_border_data">
                            <div className="div_input space_div">
                                <input type="text" onChange={ (e)=>{ HandleData('title_1', index, index_1, e.target.value) } } value={ data.title_1 } maxLength="140"/>
                                <span className="name_input">Título</span>
                            </div>
                            <div className="div_text">
                                <div className="show_contents">
                                    <JoditEditor config={ config } value={ data.text_1 == '' ? null : data.text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleData('text_1', index, index_1, newContent) } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
        } 
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_ExpandedPortfolio(idPage, showData, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="Page_ExpandedPortfolio_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Portfólio ampliado
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                {
                    showData.map((key, index)=>{
                        return(
                            <div className="show_page_data" key={ index }>
                                <div className="list_type_data">
                                    <div className="type_title">
                                        <SvgTable color="#2D3C53" className="icons" />
                                        { key.origin_ } - { key.name }
                                    </div>
                                </div>
                                {
                                    key.origin_ == "all" ?
                                        key.contents.map((key_1, index_1)=>{
                                            switch (key.idioma) {
                                                case 'pt_br':
                                                    return ShowData_PtBr(key_1.id, index, index_1, key_1);
                                                case 'en':
                                                    return ShowData_En(key_1.id, index, index_1, key_1);
                                                case 'es':
                                                    return ShowData_Es(key_1.id, index, index_1, key_1);
                                            }
                                        })
                                        :
                                        key.origin_ == "grain_harvest" ?                                            
                                            key.contents.map((key_1, index_1)=>{
                                                switch (key.idioma) {
                                                    case 'pt_br': 
                                                        return GrainHarvest_PtBr(key_1.id, index, index_1, key_1);
                                                    case 'en':
                                                        return GrainHarvest_En(key_1.id, index, index_1, key_1);
                                                    case 'es':
                                                        return GrainHarvest_Es(key_1.id, index, index_1, key_1);
                                                }
                                            })
                                        :                                     
                                            key.contents.map((key_1, index_1)=>{
                                                switch (key.idioma) {
                                                    case 'pt_br':
                                                        return UtilityTractor_PtBr(key_1.id, index, index_1, key_1);
                                                    case 'en':
                                                        return UtilityTractor_En(key_1.id, index, index_1, key_1);
                                                    case 'es':
                                                        return UtilityTractor_Es(key_1.id, index, index_1, key_1);
                                                }
                                            })
                                }
                            </div>
                        )
                    })
                }
            </form>
        </div>
    )
}