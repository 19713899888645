const ModalState = {
    Confirmation    : false,
    Profile         : false,
    ReturnResponse  : false,
    ShowFile        : false,
    Thematic        : false,
    VariousData     : false,
    OpenPartService : false,
    OpenUpgrades    : false,
    OpenOperations  : false
};

const ModalData = {
    Confirmation    : {},
    Profile         : {},
    ReturnResponse  : {},
    ShowFile        : {},
    Thematic        : {},
    VariousData     : {},
    OpenPartService : {},
    OpenUpgrades    : {},
    OpenOperations  : {}
}

const NotifyModalObserver = {
    Confirmation    : [],
    Profile         : [],
    ReturnResponse  : [],
    ShowFile        : [],
    Thematic        : [],
    VariousData     : [],
    OpenPartService : [],
    OpenUpgrades    : [],
    OpenOperations  : []
}

const NotifyModalData = {
    Confirmation    : [],
    Profile         : [],
    ReturnResponse  : [],
    ShowFile        : [],
    Thematic        : [],
    VariousData     : [],
    OpenPartService : [],
    OpenUpgrades    : [],
    OpenOperations  : []
}

// ModalData
export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}
export function GetModalData(key, value){
    return ModalData[key][value];
}
export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}

// ModalState
export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}
export function GetModalState(key, value){
    return ModalState[key][value];
}
export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}
