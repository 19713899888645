import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetModalData, SetModalState } from 'interface/PopUp';
import { GetUserData, RegisterUserData } from 'interface/Users';
import { GetListPag, SetListPag, RegisterListPag } from 'interface/Page';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw, typeCookiePage } from 'fixedData';

import { SvgDashboard, SvgDashboardExit, SvgLecture, SvgLogoTipo, SvgLogoTipo_2, SvgMachine, SvgMenuHamburguer, SvgMenuPoint, SvgSchedule, SvgSpeaker, SvgTable, SvgUpdateJson, SvgUser, SvgVariousData } from 'components/SvgFile';

import Page_AccessDashboard from 'components/Page/AccessDashboard';
import Page_AccessDashboard_Details from 'components/Page/AccessDashboard/Details';

import Page_Lecture from 'components/Page/Lecture';
import Page_Lecture_Details from 'components/Page/Lecture/Details';

import Page_Speaker from 'components/Page/Speaker';
import Page_Speaker_Details from 'components/Page/Speaker/Details';

import Page_Machine from 'components/Page/Machine';
import Page_Machine_Details from 'components/Page/Machine/Details';

import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';
import PopUP_Confirmation from 'components/PopUp/Confirmation';
import PopUP_ShowFile from 'components/PopUp/ShowFile';
import PopUP_EditProfile from 'components/PopUp/EditProfile';
import Page_VariousData from 'components/Page/VariousData';
import Page_PartsServices from 'components/Page/Table/PartsServices';
import Page_PartsServices_Details from 'components/Page/Table/PartsServices/Details';
import Page_Unimil from 'components/Page/Table/Unimil';
import Page_Unimil_Details from 'components/Page/Table/Unimil/Details';
import Page_Upgrades from 'components/Page/Table/Upgrades';
import Page_Upgrades_Details from 'components/Page/Table/Upgrades/Details';
import Page_OperationCenter from 'components/Page/Table/OperationCenter';
import Page_OperationCenter_Details from 'components/Page/Table/OperationCenter/Details';
import Page_ExpandedPortfolio from 'components/Page/Table/ExpandedPortfolio';
import Page_ExpandedPortfolio_Details from 'components/Page/Table/ExpandedPortfolio/Details';

export default function Contents(){

    const cookies = new Cookies();
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userName, setUserName ]     = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));
    const [ status, setStatus ]         = useState(false);

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
    }, []);

    function EditProfile(){
        SetModalState('Profile', true);
    }

    function CkickPage(value){
        SetListPag('currentPage', value);
    }

    function CurrentPage(){
        switch(currentPage) {
            case "accessDashoard":
                return <Page_AccessDashboard CkickPage={ CkickPage } />;
            case "accessDashoard_details":
                return <Page_AccessDashboard_Details CkickPage={ CkickPage } />;

            case "lecture":
                return <Page_Lecture CkickPage={ CkickPage } />;
            case "lecture_details":
                return <Page_Lecture_Details CkickPage={ CkickPage } />;

            case "speaker":
                return <Page_Speaker CkickPage={ CkickPage } />;
            case "speaker_details":
                return <Page_Speaker_Details CkickPage={ CkickPage } />;

            case "machine":
                return <Page_Machine CkickPage={ CkickPage } />;
            case "machine_details":
                return <Page_Machine_Details CkickPage={ CkickPage } />;

            case "various_data":
                return <Page_VariousData CkickPage={ CkickPage } />;

            case "mesa_part_service":
                return <Page_PartsServices CkickPage={ CkickPage } />;
            case "mesa_part_service_details":
                return <Page_PartsServices_Details CkickPage={ CkickPage } />;

            case "mesa_unimil":
                return <Page_Unimil CkickPage={ CkickPage } />;
            case "mesa_unimil_details":
                return <Page_Unimil_Details CkickPage={ CkickPage } />;

            case "mesa_upgrades":
                return <Page_Upgrades CkickPage={ CkickPage } />;
            case "mesa_upgrades_details":
                return <Page_Upgrades_Details CkickPage={ CkickPage } />; 

            case "mesa_operation_center":
                return <Page_OperationCenter CkickPage={ CkickPage } />;
            case "mesa_operation_center_details":
                return <Page_OperationCenter_Details CkickPage={ CkickPage } />; 

            case "expanded_portfolio":
                return <Page_ExpandedPortfolio CkickPage={ CkickPage } />;
            case "expanded_portfolio_details":
                return <Page_ExpandedPortfolio_Details CkickPage={ CkickPage } />; 

            default:
                return(
                    <div className="no_data">
                        Selecione uma opção do menu na lateral para continuar...
                    </div>
                )
        }
    }

    function UpdateJson(){
        SetModalData("Confirmation", { "origin": "update_json", "type": "update_json" });
        SetModalState('Confirmation', true);
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);

        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    return(
        <div className="Contents">
            <div className={ status == true ? "list_menu active_menu" : "list_menu" }>
                <div className="show_opt_menu">
                    <div className="show_data_top">
                        <div className="div_logo" onClick={ ()=>{ setStatus(!status) } }>
                            <div className={ status == true ? "div_logo_opt div_logo_opt_" : "div_logo_opt" }>
                                <div className="">
                                    {
                                        status == true ?
                                        <SvgLogoTipo_2 color="#ffffff" className="logotipo2" /> :
                                        <SvgLogoTipo color="#ffffff" className="logotipo" />
                                    }
                                </div>
                                {
                                    status == true ? null :
                                    <div className="">
                                        <SvgMenuHamburguer color="#ffffff" className="icons" />
                                    </div>
                                }
                            </div>
                        </div>
                        
                        <div className="opt_menu_dash">
                            {
                                userAccess == 1 ?
                                <>
                                    <div className={ currentPage == "index" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("index"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgDashboard color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Dashboard</div>
                                        </div>
                                    </div>

                                    {/* 
                                    <div className={ currentPage == "lecture" || currentPage == "lecture_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("lecture"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgLecture color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Palestra</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "speaker" || currentPage == "speaker_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("speaker"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgSpeaker color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Palestrante</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "machine" || currentPage == "machine_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("machine"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgMachine color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Equipamentos</div>
                                        </div>
                                    </div> 

                                    <div className={ currentPage == "various_data" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("various_data"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgVariousData color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Dados fixos</div>
                                        </div>
                                    </div>
                                    */}

                                    <div className={ currentPage == "expanded_portfolio" || currentPage == "expanded_portfolio_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("expanded_portfolio"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgTable color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Portfólio ampliado</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "mesa_part_service" || currentPage == "mesa_part_service_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("mesa_part_service"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgTable color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Mesa - Peças e serviços</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "mesa_upgrades" || currentPage == "mesa_upgrades_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("mesa_upgrades"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgTable color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Mesa - Upgrades</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "mesa_operation_center" || currentPage == "mesa_operation_center_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("mesa_operation_center"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgTable color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Mesa - Operations Center</div>
                                        </div>
                                    </div>

                                    <div className={ currentPage == "mesa_unimil" || currentPage == "mesa_unimil_details" ? "show_menu_dash active_dash" : status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ CkickPage("mesa_unimil"); } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgTable color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Mesa - Unimil</div>
                                        </div>
                                    </div>

                                    <div className="show_menu_dash" onClick={ ()=>{ UpdateJson() } }>
                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                            <div className="div_icons">
                                                <SvgUpdateJson color="#FFC400" className="icons_dash" />
                                            </div>
                                            <div className={ status == true ? "menu_close" : "" }>Atualizar plataforma</div>
                                        </div>
                                    </div>
                                </> 
                                :
                                <>
                                    {
                                        userPage.map((key, index)=>{

                                            if(key.page == 'updateJson'){                                            
                                                return(
                                                    <div className="show_menu_dash" onClick={ ()=>{  } } key={ index }>
                                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                            <div className="div_icons">
                                                                <SvgUpdateJson color="#FFC400" className="icons_dash" />
                                                            </div>
                                                            <div className={ status == true ? "menu_close" : "" }>
                                                                Atualizar plataforma
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }else {
                                                let iconMenu   = '';
                                                switch (key.page) {
                                                    case 'lecture':
                                                            iconMenu = <SvgLecture color="#FFC400" className="icons_dash" />;
                                                        break;
                                                    case 'speaker':
                                                            iconMenu = <SvgSpeaker color="#FFC400" className="icons_dash" />;
                                                        break;
                                                    case 'machine':
                                                            iconMenu = <SvgMachine color="#FFC400" className="icons_dash" />;
                                                        break;
                                                }
                                                
                                                let activeMenu = '';
                                                if(key.page == 'lecture' && currentPage == 'lecture_details'){
                                                    status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                                }else if(key.page == 'speaker' && currentPage == 'speaker_details'){
                                                    status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                                }else if(key.page == 'machine' && currentPage == 'machine_details'){
                                                    status == true ? activeMenu = "show_menu_dash active_dash show_menu_dash_": activeMenu = "show_menu_dash active_dash";

                                                }else {
                                                    status == true ? activeMenu = "show_menu_dash show_menu_dash_": activeMenu = "show_menu_dash";
                                                }
                                            
                                                return(
                                                    <div className={ activeMenu } onClick={ ()=>{ CkickPage(key.page); } } key={ index }>
                                                        <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                                            <div className="div_icons">
                                                                { iconMenu }
                                                            </div>
                                                            <div className={ status == true ? "menu_close" : "" }>
                                                                { key.name }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </>
                            }

                            <div className={ status == true ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                                <div className={ status == true ? "name_menu name_menu_" : "name_menu" }>
                                    <div className="div_icons">
                                        <SvgDashboardExit color="#FFC400" className="icons_dash" />
                                    </div>
                                    <div className={ status == true ? "menu_close" : "" }>Sair</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div_profile">
                        <div className={ status == true ? "div_profile_data div_profile_data_" : "div_profile_data" } onClick={ ()=>{ EditProfile() } }>
                            <div className="div_img">
                                {
                                    userFile !='' ?
                                    <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                                    <SvgUser color="#FFFFFF" className="show_img_profile" />
                                }
                            </div>
                            <div className={ status == true ? "name_user menu_close" : "name_user" }>
                                { userName }
                            </div>
                            <div className={ status == true ? "open_profile menu_close" : "open_profile" }>
                                <SvgMenuPoint color="#ffffff" className="icons" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_ReturnResponse />
            <PopUP_Confirmation />
            <PopUP_ShowFile />
            <PopUP_EditProfile />
        </div>
    )
}
