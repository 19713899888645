import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Reg_Speaker } from "services/RegisterData";

import { SvgDelete, SvgImg, SvgLecture, SvgSave, SvgSetaRight, SvgSpeaker } from "components/SvgFile";

export default function Page_Speaker_Details(){

    const [ loading, setLoading ]         = useState(false);
    const [ idUser, setIdUser ]           = useState(GetUserData("id"));
    const [ dataPage, setDataPage ]       = useState(GetDataPage('speaker'));
    const [ dataLecture, setDataLecture ] = useState(GetDataPage('lecture'));
    const [ idPage, setIdPage ]           = useState(GetListPag('currentPageId'));

    const [ highlight, setHighlight ] = useState(InitialData('highlight'));
    const [ name, setName ]           = useState(InitialData('name'));
    const [ office, setOffice ]       = useState(InitialData('office'));
    const [ contact, setContact ]     = useState(InitialData('contact'));
    const [ file, setFile ]           = useState(InitialData('file'));
    const [ biography, setBiography ] = useState(InitialData('biography'));
    const [ lecture, setLecture ]     = useState(InitialData('lecture'));

    const [ newLecture, setNewLecture ] = useState([]);
    const [ status, setStatus ]         = useState(false);
    const [ statusFile, setStatusFile ] = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage('speaker', setDataPage);
        RegisterDataPage('lecture', setDataLecture);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('speaker'));
        setIdPage(GetListPag('currentPageId'));

        setHighlight(InitialData('highlight'));
        setName(InitialData('name'));
        setOffice(InitialData('office'));
        setContact(InitialData('contact'));
        setFile(InitialData('file'));
        setBiography(InitialData('biography'));
        setLecture(InitialData('lecture'));

        setNewLecture([]);
        setStatus(false);
        setStatusFile(false);
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('speaker'));
        setIdPage(GetListPag('currentPageId'));

        setHighlight(InitialData('highlight'));
        setName(InitialData('name'));
        setOffice(InitialData('office'));
        setContact(InitialData('contact'));
        setFile(InitialData('file'));
        setBiography(InitialData('biography'));
        setLecture(InitialData('lecture'));

        setNewLecture([]);
        setStatus(false);
        setStatusFile(false);
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);

            if(type == 'highlight'){
                if(newData['highlight'] == 0){
                    return { "value": 0, "label": "Não" }
                }else {
                    return { "value": 1, "label": "Sim" }
                }
            }

            return newData[type];
        }
        if(type == 'lecture'){
            return [];
        }
        return '';
    }

    function ListLecture(){
        const options = []
        dataLecture.map((key, index)=>{
            let label = "Título: " + key.title + " - Início: " + key.startDate_br + " - Término: " + key.endDate_br;
            options.push({
                "value": key.id, "label": label
            });
        });
        return options;
    }

    function DeleteData(id, value){
        SetModalData("Confirmation", { "id": id, "origin": "speaker", "type": "delete_lecture", "name": value });
        SetModalState('Confirmation', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'speaker');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Speaker(idPage, highlight, name, office, contact, file, biography, newLecture, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function AddFile(value){
        if(value){
            setStatusFile(true);
            setFile(value);
        }else {
            setStatusFile(false);
            setFile('');
        }
    }

    return(
        <div className="Page_Speaker_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Palestrantre
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            <SvgSpeaker color="#2D3C53" className="icons" />
                            Dados do paletrante
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="name_input">Nome</span>
                        </div>
                        <div className="div_input">
                            <Select className="select_highlight" defaultOptions options={[ { "value": 0, "label": "Não" }, { "value": 1, "label": "Sim" } ]} onChange={ (e)=> { setHighlight(e) } } value={ highlight } placeholder="..." />
                            <span className="name_input">Destacar</span>
                        </div>
                        
                        <div className={ statusFile == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                            <label>
                                { statusFile == true ? "Imagem adicionada" : "Adicionar imagem" }
                                <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                            </label>
                        </div>
                        {
                            file !='' ?
                                statusFile == true ?
                                <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div> :
                                <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "speaker/" + file) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div>
                            : null
                        }
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setOffice(e.target.value) } } value={ office } maxLength="140" />
                            <span className="name_input">Cargo</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setContact(e.target.value) } } value={ contact } maxLength="30" />
                            <span className="name_input">Contato</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Descrição</div>
                            <JoditEditor config={ config } value={ biography ? biography.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setBiography(newContent) } />
                        </div>
                    </div> 
                </div>
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">
                            <SvgLecture color="#2D3C53" className="icons" />
                            Palestra
                        </div>
                        <div className="">
                            <div className="new_data_page" onClick={ ()=>{ setStatus(!status) } }>
                                { status == false ? "+ Palestra" : "Ocultar" }
                            </div>
                        </div>
                    </div>
                    {
                        status == false ? null :
                        <div className="list_input_data" style={ { marginBottom: '16px' } }>
                            <div className="div_input space_div">
                                <Select className="select_options" defaultOptions options={ ListLecture() } onChange={ (e)=> { setNewLecture(e) } } value={ newLecture } placeholder="..." isMulti />
                                <span className="name_input">Selecione as palestras</span>
                            </div>
                        </div>
                    }
                    {
                        lecture.length > 0 ?
                            <div className="list_data_list">
                                {
                                    lecture.map((key, index) =>{
                                        return(
                                            <div className="div_data_list" key={ index }>
                                                <div className="div_show_data_list">
                                                    <div className="">
                                                        <span className="highlight">Título:</span> { key.title }
                                                    </div>
                                                    <div className="">
                                                        <span className="highlight">Início:</span> { key.startDate }
                                                    </div>
                                                    <div className="">
                                                        <span className="highlight">Término:</span> { key.endDate }
                                                    </div>
                                                </div>
                                                <div className="div_delete_data_list" onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                    <SvgDelete color="#F00000" className="icons" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        :
                        <>
                            {
                                status == true ? null :                        
                                <div className="list_input_data" style={ { marginBottom: 0 } }>
                                    <div className="no_data">Nenhuma palestra encontrada...</div>
                                </div>
                            }
                        </>
                    }
                </div>
            </form>
        </div>
    )
}