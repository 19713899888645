import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { SvgAddData, SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgTable } from "components/SvgFile";
import { Reg_RegisterUnimil } from "services/RegisterData";

export default function Page_Unimil_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ idUser, setIdUser ]     = useState(GetUserData("id"));
    const [ dataPage, setDataPage ] = useState(GetDataPage('mesa_unimil'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ origin, setOrigin ] = useState(InitialData('origin_'));

    const [ btnTitle_1, setBtnTitle_1 ]       = useState(InitialData('btnTitle_1'));
    const [ btnSubtitle_1, setBtnSubtitle_1 ] = useState(InitialData('btnSubtitle_1'));
    const [ btnImg_1, setBtnImg_1 ]           = useState(InitialData('btnImg_1'));
    const [ btnTexto_1, setBtnTexto_1 ]       = useState(InitialData('btnTexto_1'));
    const [ btnStatus_1, setBtnStatus_1 ]     = useState(false);

    const [ btnTitle_2, setBtnTitle_2 ]       = useState(InitialData('btnTitle_2'));
    const [ btnSubtitle_2, setBtnSubtitle_2 ] = useState(InitialData('btnSubtitle_2'));
    const [ btnImg_2, setBtnImg_2 ]           = useState(InitialData('btnImg_2'));
    const [ btnTexto_2, setBtnTexto_2 ]       = useState(InitialData('btnTexto_2'));
    const [ btnStatus_2, setBtnStatus_2 ]     = useState(false);
    
    const [ btnTitle_3, setBtnTitle_3 ]       = useState(InitialData('btnTitle_3'));
    const [ btnSubtitle_3, setBtnSubtitle_3 ] = useState(InitialData('btnSubtitle_3'));
    const [ btnImg_3, setBtnImg_3 ]           = useState(InitialData('btnImg_3'));
    const [ btnTexto_3, setBtnTexto_3 ]       = useState(InitialData('btnTexto_3'));
    
    const [ macSubtitle_1, setMacSubtitle_1 ] = useState(InitialData('macSubtitle_1'));
    const [ macQrCode_1, setMacQrCode_1 ]     = useState(InitialData('macQrCode_1'));
    const [ macStatus_1, setMacStatus_1 ]     = useState(false);
    
    const [ macSubtitle_2, setMacSubtitle_2 ] = useState(InitialData('macSubtitle_2'));
    const [ macQrCode_2, setMacQrCode_2 ]     = useState(InitialData('macQrCode_2'));
    const [ macImgExample, setMacImgExample ] = useState(InitialData('macImgExample')); 
    const [ macQrCodeStatus_2, setMacQrCodeStatus_2 ] = useState(false);
    const [ macStatus_2, setMacStatus_2 ]             = useState(false);
    
    const [ macList, setMacList ] = useState(InitialData('macList'));

    useEffect(()=>{
        RegisterUserData('id', setIdUser);
        RegisterDataPage('mesa_unimil', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('mesa_unimil'));
        setIdPage(GetListPag('currentPageId')); 

        setOrigin(InitialData('origin_'));
        setBtnTitle_1(InitialData('btnTitle_1'));
        setBtnSubtitle_1(InitialData('btnSubtitle_1'));
        setBtnImg_1(InitialData('btnImg_1'));
        setBtnTexto_1(InitialData('btnTexto_1'));
        setBtnStatus_1(false);

        setBtnTitle_2(InitialData('btnTitle_2'));
        setBtnSubtitle_2(InitialData('btnSubtitle_2'));
        setBtnImg_2(InitialData('btnImg_2'));
        setBtnTexto_2(InitialData('btnTexto_2'));
        setBtnStatus_2(false);

        setBtnTitle_3(InitialData('btnTitle_3'));
        setBtnSubtitle_3(InitialData('btnSubtitle_3'));
        setBtnImg_3(InitialData('btnImg_3'));
        setBtnTexto_3(InitialData('btnTexto_3'));
        
        setMacSubtitle_1(InitialData('macSubtitle_1'));
        setMacQrCode_1(InitialData('macQrCode_1'));
        setMacList(InitialData('macList'));
        setMacStatus_1(false);
        
        setMacSubtitle_2(InitialData('macSubtitle_2'));
        setMacQrCode_2(InitialData('macQrCode_2'));
        setMacImgExample(InitialData('macImgExample'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('mesa_unimil'));
        setIdPage(GetListPag('currentPageId')); 

        setOrigin(InitialData('origin_'));
        setBtnTitle_1(InitialData('btnTitle_1'));
        setBtnSubtitle_1(InitialData('btnSubtitle_1'));
        setBtnImg_1(InitialData('btnImg_1'));
        setBtnTexto_1(InitialData('btnTexto_1'));
        setBtnStatus_1(false);

        setBtnTitle_2(InitialData('btnTitle_2'));
        setBtnSubtitle_2(InitialData('btnSubtitle_2'));
        setBtnImg_2(InitialData('btnImg_2'));
        setBtnTexto_2(InitialData('btnTexto_2'));
        setBtnStatus_2(false);

        setBtnTitle_3(InitialData('btnTitle_3'));
        setBtnSubtitle_3(InitialData('btnSubtitle_3'));
        setBtnImg_3(InitialData('btnImg_3'));
        setBtnTexto_3(InitialData('btnTexto_3'));
        
        setMacSubtitle_1(InitialData('macSubtitle_1'));
        setMacQrCode_1(InitialData('macQrCode_1'));
        setMacList(InitialData('macList'));
        setMacStatus_1(false);
        
        setMacSubtitle_2(InitialData('macSubtitle_2'));
        setMacQrCode_2(InitialData('macQrCode_2'));
        setMacImgExample(InitialData('macImgExample'));
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'origin_'){
                if(newData[type] == 'about'){
                    return { "value": "about", "label": "Sobre a empresa" };
                }else if(newData[type] == 'catalog_unimil_cana'){
                    return { "value": "catalog_unimil_cana", "label": "Catálogo - Unimil Cana" };
                }else if(newData[type] == 'catalog_unimil_by_john_deere'){
                    return { "value": "catalog_unimil_by_john_deere", "label": "Catálogo - Unimil by John Deere" };
                }
            }
            return newData[type];
        }
        if(type == 'btnImg_3' || type == 'macList'){
            return [];
        }
        if(type == 'origin_'){
            return { "value": "about", "label": "Sobre a empresa" };
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'mesa_unimil');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_RegisterUnimil(idPage, origin, btnTitle_1, btnSubtitle_1, btnImg_1, btnTexto_1, btnTitle_2, btnSubtitle_2, btnImg_2, btnTexto_2, btnTitle_3, btnSubtitle_3, btnImg_3, btnTexto_3, macSubtitle_1, macQrCode_1, macList, macSubtitle_2, macQrCode_2, macImgExample, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function AddFile(type, value){
        switch (type) {
            case 'btn_1':
                    if(value){
                        setBtnStatus_1(true);
                        setBtnImg_1(value);
                    }else {
                        setBtnStatus_1(false);
                        setBtnImg_1('');
                    }
                break;

            case 'btn_2':
                    if(value){
                        setBtnStatus_2(true);
                        setBtnImg_2(value);
                    }else {
                        setBtnStatus_2(false);
                        setBtnImg_2('');
                    }
                break;

            case 'mach_1':
                    if(value){
                        setMacStatus_1(true);
                        setMacQrCode_1(value);
                    }else {
                        setMacStatus_1(false);
                        setMacQrCode_1('');
                    }
                break;

            case 'mach_2':
                    if(value){
                        setMacQrCodeStatus_2(true);
                        setMacQrCode_2(value);
                    }else {
                        setMacQrCodeStatus_2(false);
                        setMacQrCode_2('');
                    }
                break;
                
            case 'mach_3':
                    if(value){
                        setMacStatus_2(true);
                        setMacImgExample(value);
                    }else {
                        setMacStatus_2(false);
                        setMacImgExample('');
                    }
                break;
        }
    }

    function OpenFile(type, value){
        SetModalData("ShowFile", { "type": type, "file": value });
        SetModalState('ShowFile', true);
    }

    function HandleBtn(index, value){
        const newData = [...btnImg_3];
        if(value){
            newData[index].img = value;
            newData[index].status = true;
        }else {
            newData[index].img    = '';
            newData[index].status = false;
        }
        setBtnImg_3(newData);
    }

    function DeleteData(type, id, index, value, index_1){
        if(type == 'button'){
            if(id == 0){
                const newData = [...macList];
                newData.splice(index, 1);
                setMacList(newData);
            }else {
                SetModalData("Confirmation", { "id": id, "origin": "mesa_unimil", "type": "delete_buttons", "name": value });
                SetModalState('Confirmation', true);
            }
        }else {
            if(id == 0){
                const newData = [...macList];
                newData[index].list.splice(index_1, 1);
                setMacList(newData)
            }else {
                SetModalData("Confirmation", { "id": id, "origin": "mesa_unimil", "type": "delete_photo", "name": value });
                SetModalState('Confirmation', true);
            }
        }
    }

    function NewData_list_1(index){
        const newData = [...macList];
        newData[index].list.push({ "id": 0, "status": false, "name": "", "reference": "", "img": "" });
        setMacList(newData);
    }

    function HandleData_1(type, index, value){
        const newData = [...macList];
         newData[index][type] = value;
        setMacList(newData);
    }

    function HandleDataFile_1(type, index, index_1, value){
        const newData = [...macList];
        if(type == 'img'){
            newData[index].list[index_1][type] = value;
            if(value){
                newData[index].list[index_1].status = true;
            }else {
                newData[index].list[index_1].status = false;
            }
        }else {
            newData[index].list[index_1][type] = value;
        }
        setMacList(newData);
    }

    return(
        <div className="Page_Unimil_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Unimil
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#CDD1D6" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">catalog_unimil_cana</div>
                        <div className="">
                            <div className="new_data_page" onClick={ ()=>{ setMacList([...macList, { "id": 0, "title": "", "subtitle": "", "status": false, "img": "", "text": "", "list": [] }]) } }>
                                + Adicionar botão
                            </div>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setMacSubtitle_1(e.target.value) } } value={ macSubtitle_1 } maxLength="255" />
                            <span className="name_input">Título</span>
                        </div>
                        <div className={ macStatus_1 == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                            <label>
                                { macStatus_1 == true ? "QrCode adicionada" : "Adicionar QrCode" }
                                <input type="file" onChange={ (e)=>{ AddFile('mach_1', e.target.files[0]); } } accept="image/*" />
                            </label>
                        </div>
                        {
                            macQrCode_1 !='' ?
                                macStatus_1 == true ?
                                <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(macQrCode_1)) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div> :
                                <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/unimil/" + macQrCode_1) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div>
                            : null
                        }      
                    </div>
                    {
                        macList.length > 0 ?
                            <div className="">
                                {
                                    macList.map((key, index) =>{
                                        return(
                                            <div className="div_border_data" key={ index }>
                                                <div className="list_input_data">
                                                    <div className="div_input">
                                                        <input type="text" onChange={ (e)=>{ HandleData_1('title', index, e.target.value) } } value={ key.title } maxLength="60" />
                                                        <span className="name_input">Título</span>
                                                    </div>
                                                    <div className="div_input space_div">
                                                        <input type="text" onChange={ (e)=>{ HandleData_1('subtitle', index, e.target.value) } } value={ key.subtitle } maxLength="60" />
                                                        <span className="name_input">Subtitulo</span>
                                                    </div>
                                                    <div className="div_input space_data">
                                                        <div onClick={ ()=>{ DeleteData('button', key.id, index, key.title) } }>
                                                            <SvgDelete className="icons" color="#f00000" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="list_input_data">                   
                                                    <div className="div_input space_div">
                                                        <JoditEditor config={ config } value={ key.text ? key.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => HandleData_1('text', index, newContent) } />
                                                    </div>
                                                </div>
                                                <div className="list_input_data">
                                                    <div className="div_input new_data_page" onClick={ ()=>{ NewData_list_1(index) } } style={ { marginTop: '0px' } }>
                                                        Adicionar nova foto
                                                    </div>
                                                </div>
                                                <div className="list_input_data">
                                                    {
                                                        key.list.map((key_1, index_1)=>{
                                                            return(
                                                                <div className="" key={ index_1 } style={ { display: 'flex', gap: '10px', border: '2px solid #ebebeb', padding: '14px' } }>
                                                                    <div className="div_input">
                                                                        <input type="text" onChange={ (e)=>{ HandleDataFile_1('name', index, index_1, e.target.value) } } value={ key_1.name } style={ { width: '300px' } } maxLength="60" />
                                                                        <span className="name_input">Nome</span>
                                                                    </div>
                                                                    <div className="div_input">
                                                                        <input type="text" className="reference" onChange={ (e)=>{ HandleDataFile_1('reference', index, index_1, e.target.value) } } value={ key_1.reference } maxLength="60" />
                                                                        <span className="name_input">Referência</span>
                                                                    </div>
                                                                    <div className={ key_1.status == true ? "div_input new_file_add new_file_add_active" : "div_input new_file_add" }>
                                                                        <label>
                                                                            { key_1.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                                            <input type="file" onChange={ (e)=>{ HandleDataFile_1('img', index, index_1, e.target.files[0]); } } accept="image/*" />
                                                                        </label>
                                                                    </div>
                                                                    {
                                                                        key_1.img !='' ?
                                                                            key_1.status == true ?
                                                                                <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key_1.img)) } } style={ { marginTop: '18px' } }>
                                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                                </div> :
                                                                                <div className="div_input show_img_" onClick={ ()=>{ OpenFile('showImg', "mesa/unimil/" + key_1.img) } } style={ { marginTop: '18px' } }>
                                                                                    <SvgImg color="#324d6b" className="icons"/>
                                                                                </div>
                                                                        : null
                                                                    } 
                                                                    <div className="div_input" style={ { marginTop: '18px' } }>
                                                                        <div onClick={ ()=>{ DeleteData('img', key_1.id, index, key_1.name, index_1) } }>
                                                                            <SvgDelete className="icons" color="#f00000" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        : null
                    }
                </div>
            </form>
        </div>
    )
}